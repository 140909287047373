// import { ToastsStore } from "react-toasts"

const ToastManager = () => {
    // const { notification, notificationType } = useSelector(
    //     selectNotificationState
    // )
    // const timer = 5000
    // useEffect(() => {
    //     if (notification && notificationType) {
    //         switch (notificationType) {
    //             case "success": {
    //                 ToastsStore.success(
    //                     (<Toast text={notification} />) as any,
    //                     timer
    //                 )
    //                 break
    //             }
    //             case "error": {
    //                 ToastsStore.error(
    //                     (<Toast text={notification} />) as any,
    //                     timer
    //                 )
    //                 break
    //             }
    //             case "info": {
    //                 ToastsStore.info(
    //                     (<Toast text={notification} />) as any,
    //                     timer
    //                 )
    //                 break
    //             }
    //             case "warning": {
    //                 ToastsStore.warning(
    //                     (<Toast text={notification} />) as any,
    //                     timer
    //                 )
    //                 break
    //             }
    //             default: {
    //                 ToastsStore.info(
    //                     (<Toast text={notification} />) as any,
    //                     timer
    //                 )
    //             }
    //         }
    //     }
    // }, [notification, notificationType])
    //
    return <div />
}

export default ToastManager

const BASE_KEY = "cllReffWeb-"

type CllWebAppKeys = "token" | "accountLang"

export const getFromStorage = (name: CllWebAppKeys) => {
    if (typeof window === "undefined") return null
    try {
        const data = window.localStorage.getItem(`${BASE_KEY}${name}`)
        if (data === null || data === undefined || data === "undefined") {
            return null
        }
        return JSON.parse(data)
    } catch (error) {
        console.error(error)
    }
}

export const getAccessToken = () => {
    const base = getFromStorage("token") as any
    return base?.access_token ?? null
}

export const getRefreshToken = () => {
    const base = getFromStorage("token") as any
    return base?.refresh_token ?? null
}

export const addToStorage = (name: CllWebAppKeys, value: any) => {
    if (typeof window === "undefined") return null

    try {
        window.localStorage.setItem(`${BASE_KEY}${name}`, JSON.stringify(value))
    } catch (error) {
        return null
    }
}

export const removeFromStorage = (name: CllWebAppKeys) => {
    if (typeof window === "undefined") return null
    try {
        localStorage.removeItem(`${BASE_KEY}${name}`)
    } catch (error) {
        return null
    }
}

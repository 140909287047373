import { DEFAULT_CONFIG } from "./Config"
import store from "./Store"
import axios, { AxiosRequestConfig } from "axios"
import toast from "react-hot-toast"
import { addToStorage, getFromStorage } from "lib/Storage"
import { ISession } from "utils/Types/AuthTypes"
import { logout, refreshToken } from "modules/login/loginQueries"
import { useAuthStore } from "hooks/useAuthStore"

export const AxiosApi = axios.create()

const isDevEnv = import.meta.env.MODE === "development"

const handleTokenFailure = (error: any) => {
    const msg = "Inicie sesión para continuar."
    const lang = store.getState().lang.code

    if (isDevEnv) {
        console.info(
            `TOKEN COULD NOT BE REFRESHED ${msg}: ${JSON.stringify(error)}`
        )
    }
    useAuthStore.setState({ authStatus: "unauthorized" })
    toast.error(
        lang === "es"
            ? "No pudimos refrescar la sesión, favor ingrese nuevamente"
            : "Could not refresh session, please login"
    )
    logout()
    return null
}

AxiosApi.interceptors.request.use(
    (conf) => {
        return conf
    },
    (error) => {
        return Promise.reject(error)
    }
)
AxiosApi.interceptors.response.use(
    (response) => {
        return Promise.resolve(response)
    },
    (error) => {
        const originalRequest = error.config
        if (error.response?.status === 401 && !originalRequest._retry) {
            const token = getFromStorage("token") as ISession | null
            if (!token?.refresh_token) {
                return handleTokenFailure(error)
            }

            originalRequest._retry = true
            return refreshToken({ refresh_token: token?.refresh_token })
                .then((response) => {
                    if (!response?.access_token) return handleTokenFailure(null)

                    addToStorage("token", response)
                    useAuthStore.setState({ authStatus: "authorized" })

                    originalRequest.headers["Authorization"] =
                        `Bearer ${response.access_token}`
                    return AxiosApi(originalRequest)
                })
                .catch(() => {
                    return handleTokenFailure(null)
                })
        }

        return Promise.reject(error)
    }
)
export function secureHeader(): AxiosRequestConfig {
    const session = getFromStorage("token") as ISession | null

    return {
        headers: {
            Authorization: "Bearer " + session?.access_token,
        },
    }
}

/** ***********************PUBLIC METHODS ************************************************ **/

export function publicGet(path: string, params?: object, signal?: AbortSignal) {
    return AxiosApi.get(`${DEFAULT_CONFIG.server}/${path}`, {
        params,
        signal,
    })
}

export function publicPost(path: string, body: any, signal?: AbortSignal) {
    return AxiosApi.post(`${DEFAULT_CONFIG.server}/${path}`, body, {
        signal,
    })
}

/** *********************WITH BASIC AUTH*************************************************** **/
export function basicAuthGet(
    path: string,
    params?: object,
    config?: AxiosRequestConfig
) {
    return AxiosApi.get(`${DEFAULT_CONFIG.server}/${path}`, {
        auth: {
            username: DEFAULT_CONFIG.basic_auth_username,
            password: DEFAULT_CONFIG.basic_auth_password,
        },
        params,
        ...config,
    })
}

export function basicAuthPost(
    path: string,
    body: any,
    config?: AxiosRequestConfig
) {
    return AxiosApi.post(`${DEFAULT_CONFIG.server}/${path}`, body, {
        auth: {
            username: DEFAULT_CONFIG.basic_auth_username,
            password: DEFAULT_CONFIG.basic_auth_password,
        },
        ...config,
    })
}

/** ************************************************************************************** **/
export function secureGet(
    path: string,
    params?: object | string,
    config?: AxiosRequestConfig
) {
    return AxiosApi.get(`${DEFAULT_CONFIG.server}/${path}`, {
        ...secureHeader(),
        params,
        ...config,
    })
}
export function secureAffiliateGet(
    path: string,
    params?: object | string,
    config?: AxiosRequestConfig
) {
    return AxiosApi.get(`${DEFAULT_CONFIG.affiliates_server}/${path}`, {
        ...secureHeader(),
        params,
        ...config,
    })
}

export function securePost(
    path: string,
    body = {},
    config?: AxiosRequestConfig
) {
    return AxiosApi.post(`${DEFAULT_CONFIG.server}/${path}`, body, {
        ...secureHeader(),
        ...config,
    })
}

export function secureAffiliatePost(
    path: string,
    body = {},
    config?: AxiosRequestConfig
) {
    return AxiosApi.post(`${DEFAULT_CONFIG.affiliates_server}/${path}`, body, {
        ...secureHeader(),
        ...config,
    })
}
export function secureAffiliatePatch(
    path: string,
    body = {},
    config?: AxiosRequestConfig
) {
    return AxiosApi.patch(`${DEFAULT_CONFIG.affiliates_server}/${path}`, body, {
        ...secureHeader(),
        ...config,
    })
}

import { useState } from "react"
import { languages } from "../../translations"
import ListItemText from "@mui/material/ListItemText"
import ListItemIcon from "@mui/material/ListItemIcon"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { useSelector } from "react-redux"
import PdfIcon from "../icon/PdfIcon"
import { selectLangState, setLanguage } from "./store/langSlice"
import { useAppDispatch } from "config/Store"
import { themeText } from "styles/MuiTheme"
import { selectThemeMode } from "styles/stylesSlice"

const LanguagePicker = () => {
    const dispatch = useAppDispatch()
    const themeMode = useSelector(selectThemeMode)
    const { code } = useSelector(selectLangState)
    const [languageEl, setLanguageEl] = useState(null)

    const handleOpenLanguage = (event: any) =>
        setLanguageEl(event.currentTarget)
    const handleCloseLanguage = () => setLanguageEl(null)

    const chooseLanguage = (val: "es" | "en") => {
        dispatch(setLanguage(val))
        handleCloseLanguage()
    }

    return (
        <>
            <button onClick={handleOpenLanguage}>
                <PdfIcon width={20} icon={`lang/${code}`} alt="" ext="svg" />
                <span
                    style={{
                        color: themeText(themeMode),
                    }}
                    className="desktop--on"
                >
                    {code}
                </span>
            </button>
            <Menu
                id="simple-menu"
                anchorEl={languageEl}
                keepMounted
                open={Boolean(languageEl)}
                onClose={handleCloseLanguage}
            >
                {languages.map((lang, index) =>
                    lang.value === code ? null : (
                        <MenuItem
                            dense
                            key={index}
                            onClick={() => chooseLanguage(lang.value)}
                        >
                            <ListItemIcon>
                                <PdfIcon
                                    width={16}
                                    icon={`lang/${lang.flag}`}
                                    alt=""
                                    ext="svg"
                                />
                            </ListItemIcon>
                            <ListItemText
                                sx={{
                                    textTransform: "capitalize",
                                }}
                                primary={lang.name}
                            />
                        </MenuItem>
                    )
                )}
            </Menu>
        </>
    )
}

export default LanguagePicker

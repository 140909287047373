import Button from "@mui/material/Button"
import toast from "react-hot-toast"
import useTranslations from "translations"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import Typography from "@mui/material/Typography"

const CopyLinkCellButton = ({
    link,
    disabled,
    children,
}: {
    link: string
    disabled?: boolean
    children?: React.ReactNode
}) => {
    const lang = useTranslations().Code
    return (
        <>
            <Button
                disabled={disabled}
                onClick={(e) => {
                    e.stopPropagation()
                    navigator.clipboard.writeText(link)
                    toast.success(lang === "ES" ? "Copiado!" : "Copied!")
                }}
                sx={{ alignItems: "center", display: "flex", gap: 1 }}
            >
                <Typography>{children}</Typography>
                <ContentCopyIcon fontSize="small" />
            </Button>
        </>
    )
}

export default CopyLinkCellButton

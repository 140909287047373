import {
    secureAffiliateGet,
    secureAffiliatePatch,
    secureAffiliatePost,
} from "config/AxiosRequest"
import { IPromotor, IPromotorFormData } from "./PromotorTypes"
import { AxiosResponse } from "axios"

export const mutateCreateAffiliate = async (
    data: IPromotorFormData
): Promise<AxiosResponse<IPromotor>> => {
    const response = (await secureAffiliatePost(
        `affiliates`,
        data
    )) as AxiosResponse<IPromotor>

    return response
}
export const mutateUpdateAffiliate = async (
    data: IPromotorFormData
): Promise<AxiosResponse<IPromotor>> => {
    const response = (await secureAffiliatePatch(
        `affiliates/${data.userId}`,
        data
    )) as AxiosResponse<IPromotor>

    return response
}

export const getPromotors = async (): Promise<IPromotor[] | null> => {
    const response = (await secureAffiliateGet(`affiliates`)) as AxiosResponse<
        IPromotor[]
    >
    if (response.status !== 200) return null

    return response.data
}

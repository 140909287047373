import { Navigate } from "react-router-dom"
import { useAuthStore } from "hooks/useAuthStore"

const PrivateRoute = ({
    allowedRoles,
    forbiddenRoles,
    children,
}: {
    allowedRoles?: string[]
    forbiddenRoles?: string[]
    children: React.ReactNode
}) => {
    const { user } = useAuthStore()

    if (
        user &&
        allowedRoles &&
        !user.groups.some((group) => allowedRoles.includes(group.name))
    ) {
        return <Navigate to="/" />
    }

    if (
        user &&
        forbiddenRoles &&
        user.groups.some((group) => forbiddenRoles.includes(group.name))
    ) {
        return <Navigate to="/" />
    }

    return children
}

export default PrivateRoute

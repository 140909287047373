import { AxiosError } from "axios"
import { setLoadingMaskStatus } from "component/mask/store/maskSlice"
import store from "config/Store"
import { logout } from "modules/login/loginQueries"
import toast from "react-hot-toast"
const isDevEnv = import.meta.env.MODE === "development"

export const handleLoginErrors = (error: AxiosError) => {
    if (isDevEnv) {
        console.error("handleRequestErrors", error)
    }
    logout()
    toast.error("Error: " + error?.message)
}

export const queryErrorHandler = (error: unknown) => {
    if (isDevEnv) {
        console.error("queryErrorHandler", error)
    }
}
export const mutationErrorHandler = (error: unknown) => {
    if (isDevEnv) {
        console.error("mutationErrorHandler", error)
    }
    store.dispatch(setLoadingMaskStatus(false))
    return error
}

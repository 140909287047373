import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import { grey } from "@mui/material/colors"
import Typography from "@mui/material/Typography"
import useTranslations from "translations"
import { Controller, useForm, useWatch } from "react-hook-form"
import PhoneInput from "react-phone-input-2"
import useCFCountryCode from "hooks/useCFCountryCode"
import { useMutation } from "@tanstack/react-query"
import { sendInvite } from "./InviteQueries"
import { zodResolver } from "@hookform/resolvers/zod"
import {
    IInviteFormData,
    defaultInviteFormData,
    validateInviteForm,
} from "./InviteValidations"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import Switch from "@mui/material/Switch"
import { useAppDispatch } from "config/Store"
import LoadingButton from "@mui/lab/LoadingButton"
import toast from "react-hot-toast"

const InvitePage = () => {
    const t = useTranslations()
    const { country } = useCFCountryCode()
    const dispatch = useAppDispatch()

    const {
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm<IInviteFormData>({
        defaultValues: defaultInviteFormData,
        resolver: zodResolver(validateInviteForm(t.Code)),
    })

    const invitationType = useWatch({ control, name: "invitationType" })

    const { mutate, isPending: isLoading } = useMutation({
        mutationKey: ["sendInvite"],
        mutationFn: sendInvite,
        onSuccess: (res) => {
            if (res?.status !== "SENT") {
                toast.success(t.Invite.success)
                reset()
                return
            }
            toast.error(t.Invite.error)
            console.error(res)
        },
        onError: (error: any) => {
            toast.error(t.Invite.error)
            console.error(error)
        },
    })

    const submitFunc = (data: IInviteFormData) => {
        mutate({ body: data, dispatch })
    }
    return (
        <Box
            sx={{
                display: "block",
                height: "fit-content",
                minHeight: "100vh",
                backgroundColor: grey[200],
                overflow: "auto",
                py: { xs: 0, sm: 4 },
            }}
        >
            <Container
                maxWidth="sm"
                sx={{
                    bgcolor: "background.paper",
                    borderRadius: { xs: 0, sm: 2 },
                    py: 3,
                }}
            >
                <Box
                    component="form"
                    onSubmit={handleSubmit(submitFunc)}
                    sx={{
                        justifyContent: "center",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 2,
                    }}
                >
                    <Typography variant="h4">{t.Invite.title}</Typography>

                    <Stack
                        spacing={1}
                        sx={{
                            width: "100%",
                            maxWidth: "325px",
                            alignItems: "center",
                        }}
                    >
                        <Controller
                            control={control}
                            name="userContact"
                            render={({ field: { onChange, value } }) => (
                                <PhoneInput
                                    masks={{ ar: "(..) ........." }}
                                    enableSearch={true}
                                    country={country.toLowerCase()}
                                    value={value}
                                    disableSearchIcon={true}
                                    preferredCountries={["cu", "us", "es"]}
                                    specialLabel={"Numero de telefono"}
                                    onChange={onChange}
                                    isValid={errors.userContact ? false : true}
                                    containerClass={
                                        errors.userContact ? "error" : ""
                                    }
                                    disabled={isLoading}
                                    containerStyle={{
                                        maxWidth: "325px",
                                    }}
                                    // searchPlaceholder={"Search"}
                                    // searchNotFound={t('not_found')}
                                    // localization={phoneLocales[baseLocale]}
                                />
                            )}
                        />
                        {errors.userContact && (
                            <Typography
                                variant="body2"
                                color="error.main"
                                display="block"
                                align="left"
                                sx={{
                                    width: "100%",
                                    m: "3px 14px 0px 14px",
                                    lineHeight: 1.66,
                                }}
                            >
                                {errors.userContact.message}
                            </Typography>
                        )}
                    </Stack>
                    <Stack
                        sx={{
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            maxWidth: "325px",
                        }}
                    >
                        <FormControl
                            sx={{ display: "flex" }}
                            className={["w-full"].join(" ")}
                        >
                            <div className={["items-center flex"].join(" ")}>
                                <Switch
                                    checked={invitationType === "SMS"}
                                    onChange={() => {
                                        setValue("invitationType", "SMS")
                                    }}
                                />
                                <FormLabel>SMS</FormLabel>
                            </div>
                        </FormControl>

                        <FormControl
                            sx={{ display: "flex" }}
                            className={["w-full"].join(" ")}
                        >
                            <div className={["items-center flex"].join(" ")}>
                                <Switch
                                    checked={invitationType === "WHATSAPP"}
                                    onChange={() => {
                                        setValue("invitationType", "WHATSAPP")
                                    }}
                                />
                                <FormLabel>WhatsApp</FormLabel>
                            </div>
                        </FormControl>
                    </Stack>
                    <LoadingButton
                        loading={isLoading}
                        type="submit"
                        variant="contained"
                        fullWidth={true}
                        size="large"
                        sx={{ maxWidth: "325px", alignSelf: "center" }}
                    >
                        {t.Invite.button}
                    </LoadingButton>
                </Box>
            </Container>
        </Box>
    )
}

export default InvitePage

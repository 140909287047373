import "./react-tel.css"
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Toasts from "component/Toasts/Toasts"
import { AlertManager } from "component/alert/AlertManager"
import ToastManager from "modules/notification/ToastManager"
import {
    MutationCache,
    QueryCache,
    QueryClient,
    QueryClientProvider,
} from "@tanstack/react-query"
import { theme } from "styles/MuiTheme"
import { useSelector } from "react-redux"
import { selectThemeMode } from "styles/stylesSlice"
import { RouterProvider } from "react-router-dom"
import { router } from "routes/AllRoutes"
import { mutationErrorHandler, queryErrorHandler } from "utils/ErrorHandlers"
import GoogleTranslateWarningModal from "component/Modals/GoogleTranslateWarningModal"

const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: queryErrorHandler,
    }),
    mutationCache: new MutationCache({
        onError: mutationErrorHandler,
    }),
})

function App() {
    const mode = useSelector(selectThemeMode)

    return (
        <ThemeProvider theme={theme(mode)}>
            <CssBaseline />
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
                <GoogleTranslateWarningModal />
                <ToastManager />
                <Toasts />
                <AlertManager />
            </QueryClientProvider>
        </ThemeProvider>
    )
}

export default App

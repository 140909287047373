import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"
import React from "react"
import type { Control, FieldErrors, FieldValues, Path } from "react-hook-form"
import { Controller } from "react-hook-form"
import { NumericFormat } from "react-number-format"

interface InputProps<T extends FieldValues> {
    control: Control<T>
    errors: FieldErrors<T>
    helperText?: string
    hidden?: boolean
    inputLeft?: any
    inputRight?: any
    label: string
    maxLength?: number
    name: Path<T>
    required?: boolean
    isDisabled?: boolean
    className?: string
    endAdornment?: React.ReactNode
    decimalScale?: number
    asString?: boolean
    allowNegative?: boolean
    noSeparator?: boolean
}

const FormControlledNumberInput = <T extends FieldValues>({
    control,
    name,
    errors,
    label,
    helperText,
    maxLength,
    className,
    hidden,
    required,
    isDisabled,
    endAdornment,
    decimalScale,
    asString,
    allowNegative,
    noSeparator,
}: InputProps<T>) => {
    const splitName = name.split(".")
    const reducedError = splitName.reduce((acc: any, curr: any) => {
        if (!acc[curr]) return acc
        if (isNaN(curr)) {
            return acc[curr]
        }
        return acc[parseInt(curr)]
    }, errors)

    const handleHelperText = () => {
        if (typeof reducedError?.message === "string") {
            return reducedError?.message
        }
        return helperText
    }
    return (
        <FormControl
            sx={{ display: hidden ? "none" : "flex" }}
            className={["w-full", className].join(" ")}
        >
            <Controller
                control={control}
                name={name}
                render={({ field }) => (
                    <NumericFormat
                        maxLength={maxLength}
                        value={field.value}
                        allowNegative={allowNegative ?? false}
                        thousandSeparator={noSeparator ? "" : ","}
                        decimalScale={decimalScale ?? 2}
                        // prefix={prefix}
                        onValueChange={(v: any) => {
                            if (asString) {
                                return field.onChange(
                                    v.floatValue
                                        ? v?.floatValue.toString()
                                        : "0"
                                )
                            }
                            field.onChange(v?.floatValue ?? 0)
                        }}
                        onFocus={(e: any) => {
                            e.target.select()
                        }}
                        required={required}
                        disabled={isDisabled}
                        customInput={TextField}
                        error={!!reducedError.message}
                        InputProps={{
                            endAdornment: endAdornment,
                        }}
                        inputProps={{
                            maxLength: maxLength ?? 128,
                            "data-testid": name,
                        }}
                        label={label}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        helperText={handleHelperText()}
                        FormHelperTextProps={{
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore  Si funciona
                            "data-testid": `${name}-helper-text`,
                        }}
                    />
                )}
            />
        </FormControl>
    )
}

export default FormControlledNumberInput

import "./index.css"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"

import App from "./App"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import store, { persistor } from "config/Store"
import { PersistGate } from "redux-persist/integration/react"

// import * as Sentry from "@sentry/react"
// import { DEFAULT_CONFIG } from "config/Config"

// const mode = import.meta.env.MODE

// if (mode !== "development") {
//     Sentry.init({
//         dsn: "https://eebea5124c9e404538fbc256f7b34ae8@o527243.ingest.us.sentry.io/4507136133169152",
//         integrations: [
//             Sentry.browserTracingIntegration(),
//             Sentry.replayIntegration({
//                 maskAllText: false,
//                 blockAllMedia: false,
//             }),
//         ],
//         // Performance Monitoring
//         tracesSampleRate: 0.2,
//         // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//         tracePropagationTargets: ["localhost", DEFAULT_CONFIG.server],
//         // Session Replay
//         replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//         replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//         environment: import.meta.env.MODE,
//     })
// }

const container = document.getElementById("root")

{
    /* <React.StrictMode> */
}
if (container) {
    const root = createRoot(container)
    root.render(
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    )
} else {
    throw new Error(
        "Root element with ID 'root' was not found in the document. Ensure there is a corresponding HTML element with the ID 'root' in your HTML file."
    )
}

import { AxiosResponse } from "axios"
import { basicAuthPost, secureGet } from "config/AxiosRequest"
import { removeFromStorage } from "lib/Storage"
import { ISession, IUser } from "utils/Types/AuthTypes"
import { router } from "routes/AllRoutes"
import {
    ICuballamaResponse,
    IPinCompletePayload,
    IPinPartialPayload,
    IPinResponse,
} from "utils/Types/LoginTypes"
import { useAuthStore } from "hooks/useAuthStore"

export const mutateRequestPin = async (data: IPinPartialPayload) => {
    const completePayload: IPinCompletePayload = {
        ...data,
        appName: "CUBALLAMA",
        channel: "MOBILE",
    }

    const response = (await basicAuthPost(
        `uaa/login/passwordless`,
        completePayload
    )) as AxiosResponse<IPinResponse>
    //If success, save the request data
    if (response.data.code === 1105) {
        localStorage.setItem("t3mp0", JSON.stringify(data))
    }

    return response.data
}

export interface IVerifyPinResponse {
    code?: number
    access_token: string
    expires_in: string //ex 84591
    refresh_token: string
    scope: string //ex "read write"
    solicit_email: boolean
    token_type: string //ex "bearer"
    deeplink?: string
}

export interface IVerifyPinPayload {
    username: string
    grant_type: string // "password",
    device_id: string
    passwordless_pin: string
    email?: string
}

export async function mutateVerifyPin({ DATA }: { DATA: IVerifyPinPayload }) {
    const params = new URLSearchParams()
    params.append("grant_type", DATA.grant_type)
    params.append("passwordless_pin", DATA.passwordless_pin)
    params.append("device_id", DATA.device_id)
    params.append("username", DATA.username)

    const response = (await basicAuthPost(
        "uaa/oauth/token",
        params.toString(),
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        }
    )) as AxiosResponse<IVerifyPinResponse>

    return response.data
}

export async function getUserData(): Promise<IUser | null> {
    const response = (await secureGet(`uaa/secured/data`)) as AxiosResponse<
        ICuballamaResponse<IUser>
    >
    if (response.data.code !== 0) return null

    return response.data.data
}

export interface IRefreshTokenPayload {
    // grant_type: string // "password",
    // client_id: string
    refresh_token: string
}

export async function refreshToken(
    data: IRefreshTokenPayload,
    signal?: AbortSignal
) {
    const params = new URLSearchParams()
    params.append("grant_type", "refresh_token")
    // params.append("client_id", data.client_id)
    params.append("refresh_token", data.refresh_token)

    const response = (await basicAuthPost(
        "uaa/oauth/token",
        params.toString(),
        {
            signal,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        }
    )) as AxiosResponse<ISession | null>
    if (response.status !== 200) return null
    return response.data
}

export function logout() {
    removeFromStorage("token")
    useAuthStore.setState({ authStatus: "unauthorized" })
    router.navigate("/login")
}

import * as React from "react"
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles"
import MuiDrawer from "@mui/material/Drawer"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import MenuIcon from "@mui/icons-material/Menu"
import { Link } from "react-router-dom"
import { iconColor } from "styles/MuiTheme"
import { useSelector } from "react-redux"
import { selectThemeMode } from "styles/stylesSlice"
import Box from "@mui/material/Box"
import NavDrawerIconsList from "./NavDrawerIconList"

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: open ? "wrap" : "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}))

const NavDrawerDesktopSidebar = ({
    open,
    setOpen,
}: {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const theme = useTheme()
    const themeMode = useSelector(selectThemeMode)
    const handleDrawerClose = () => {
        setOpen(false)
    }
    const handleDrawerOpen = () => {
        setOpen(true)
    }
    return (
        <Drawer
            sx={{ display: { xs: "none", sm: "block", zIndex: 2 } }}
            variant="permanent"
            open={open}
        >
            <DrawerHeader>
                {open && (
                    <Link to={"/"}>
                        <img
                            width="130"
                            height="auto"
                            src="/images/picture/logo-bo-cbll-restaurantes.svg"
                            alt={"logo"}
                        />
                    </Link>
                )}
                {!open && (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            sx={{
                                color: iconColor(themeMode),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                )}
                {open && (
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                            <ChevronRightIcon />
                        ) : (
                            <ChevronLeftIcon />
                        )}
                    </IconButton>
                )}
            </DrawerHeader>
            <Divider />
            <NavDrawerIconsList open={open} setOpen={setOpen} />
        </Drawer>
    )
}

export default NavDrawerDesktopSidebar

import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
} from "react-router-dom"
import { Login } from "../modules/login/Login"
import { Pin } from "../modules/login/components/Pin/Pin"
import RootLayout from "component/layouts/RootLayout"
import { RoutesList } from "lib/RoutesList"
import ErrorBoundry from "modules/ErrorBoundry"
import PrivateRoute from "./PrivateRoute"
import InvitePage from "modules/invites/InvitePage"
import PromotorsPage from "modules/promotors/PromotorsPage"

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<RootLayout />} errorElement={<ErrorBoundry />}>
            <Route index element={<Login />} path={RoutesList.public.base} />
            <Route index element={<Login />} path={RoutesList.public.Login} />
            <Route element={<Pin />} path={RoutesList.public.Pin} />
            <Route path="/prv">
                <Route
                    index
                    element={
                        <PrivateRoute allowedRoles={["CUSTOMERS"]}>
                            <InvitePage />
                        </PrivateRoute>
                    }
                    path={RoutesList.prv.Invite}
                />
                <Route
                    element={
                        <PrivateRoute allowedRoles={["CUSTOMERS"]}>
                            <PromotorsPage />
                        </PrivateRoute>
                    }
                    path={RoutesList.prv.Promotors}
                />
            </Route>
        </Route>
    )
)

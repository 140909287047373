import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { RoutesList } from "lib/RoutesList"
import { useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { themeBg } from "styles/MuiTheme"
import { selectThemeMode } from "styles/stylesSlice"
import useTranslations from "translations"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import ExitToAppOutlined from "@mui/icons-material/ExitToAppOutlined"
import PeopleIcon from "@mui/icons-material/People"
import SendIcon from "@mui/icons-material/Send"
import Typography from "@mui/material/Typography"
import { logout } from "modules/login/loginQueries"
import { useAuthStore } from "hooks/useAuthStore"

const NavigationIcon = ({
    href,
    currentPathname,
    tooltipTitle,
    shouldHide,
    themeMode,
    Icon,
    open,
    setOpen,
}: {
    tooltipTitle: string
    shouldHide?: boolean
    currentPathname: string
    href: string
    themeMode: "light" | "dark"
    Icon: any
    open: boolean
    setOpen: (value: boolean) => void
}) => {
    return !shouldHide ? (
        <Link onClick={() => setOpen(false)} to={href}>
            <ListItem
                disablePadding
                sx={{
                    display: "block",
                }}
            >
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        mb: currentPathname === href ? "-2px" : "initial",
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                        }}
                    >
                        <Icon
                            sx={{
                                stroke: themeBg(themeMode),
                                strokeWidth: 0.5,
                                color:
                                    currentPathname === href
                                        ? "#2D71D6"
                                        : undefined,
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={
                            <Typography
                                variant="body2"
                                style={{
                                    color:
                                        currentPathname === href
                                            ? "#2D71D6"
                                            : undefined,
                                }}
                            >
                                {tooltipTitle}
                            </Typography>
                        }
                        sx={{
                            opacity: open ? 1 : 0,
                        }}
                    />
                </ListItemButton>
            </ListItem>
        </Link>
    ) : null
}

const ActionIcon = ({
    tooltipTitle,
    shouldHide,
    themeMode,
    Icon,
    open,
    setOpen,
    action,
}: {
    tooltipTitle: string
    shouldHide?: boolean
    themeMode: "light" | "dark"
    Icon: any
    open: boolean
    setOpen: (value: boolean) => void
    action: () => void
}) => {
    return !shouldHide ? (
        <ListItem
            onClick={() => {
                setOpen(false)
                action()
            }}
            disablePadding
            sx={{ display: "block" }}
        >
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                    }}
                >
                    <Icon
                        sx={{
                            stroke: themeBg(themeMode),
                            strokeWidth: 0.5,
                        }}
                    />
                </ListItemIcon>
                <ListItemText
                    disableTypography
                    primary={
                        <Typography variant="body2">{tooltipTitle}</Typography>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                />
            </ListItemButton>
        </ListItem>
    ) : null
}

const NavDrawerIconsList = ({
    open,
    setOpen,
}: {
    open: boolean
    setOpen: (value: boolean) => void
}) => {
    const { authStatus } = useAuthStore()
    const isAuthenticated = authStatus === "authorized"

    const translations = useTranslations()
    const themeMode = useSelector(selectThemeMode)
    const location = useLocation()
    const path = location.pathname

    return (
        <>
            {isAuthenticated && (
                <>
                    <List>
                        <NavigationIcon
                            open={open}
                            setOpen={setOpen}
                            href={RoutesList.prv.Invite}
                            tooltipTitle={translations.Header.linkInvite}
                            Icon={SendIcon}
                            currentPathname={path}
                            themeMode={themeMode}
                        />
                        <NavigationIcon
                            open={open}
                            setOpen={setOpen}
                            href={RoutesList.prv.Promotors}
                            tooltipTitle={translations.Header.linkPromotors}
                            Icon={PeopleIcon}
                            currentPathname={path}
                            themeMode={themeMode}
                        />
                    </List>

                    <Divider />
                </>
            )}
            <List>
                <ActionIcon
                    action={() => logout()}
                    open={open}
                    setOpen={setOpen}
                    tooltipTitle={translations.TopBar.btnLogout}
                    Icon={ExitToAppOutlined}
                    shouldHide={!isAuthenticated}
                    themeMode={themeMode}
                />
            </List>
        </>
    )
}

export default NavDrawerIconsList

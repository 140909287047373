import React from "react"
import LoadingMask from "../loading/LoadingMask"

export default function Mask({
    active,
    children,
}: {
    active: boolean
    children: React.ReactNode
}) {
    return (
        <div>
            {active && <LoadingMask />}
            {children}
        </div>
    )
}

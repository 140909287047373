import React, { useEffect } from "react"
import Typography from "@mui/material/Typography"
import useTranslations from "../../translations/useTranslations"
import { getOrCreateDeviceID } from "../../utils/Utils"
import _ from "lodash"
import "react-phone-number-input/style.css"
import { useNavigate } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"
import { grey } from "@mui/material/colors"
import { Controller, useForm } from "react-hook-form"
import PhoneInput from "react-phone-input-2"
import useCFCountryCode from "hooks/useCFCountryCode"
import { DEFAULT_CONFIG } from "config/Config"
import { useMutation } from "@tanstack/react-query"
import { mutateRequestPin } from "./loginQueries"
import LoadingButton from "@mui/lab/LoadingButton"
import { useAppDispatch } from "config/Store"
import { setLoadingMaskStatus } from "component/mask/store/maskSlice"
import { RoutesList } from "lib/RoutesList"
import toast from "react-hot-toast"
import { useSelector } from "react-redux"
import { selectLangState } from "component/language-picker/store/langSlice"
import { IPinPartialPayload } from "utils/Types/LoginTypes"
import { useAuthStore } from "hooks/useAuthStore"

export const Login = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const language = useSelector(selectLangState).code
    const t = useTranslations()
    const recaptchaRef = React.createRef()
    const { country } = useCFCountryCode()
    const { authStatus } = useAuthStore()

    useEffect(() => {
        localStorage.removeItem("t3mp0")
        localStorage.removeItem("v4lP1")
        localStorage.removeItem("3m41l")
        localStorage.removeItem("3m41l3rr0r")
        if (authStatus === "authorized") {
            navigate(RoutesList.prv.Invite)
        }
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authStatus])

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IPinPartialPayload>({
        defaultValues: {
            phoneNumber: "",
            captcha: "",
            deviceId: getOrCreateDeviceID(),
            pinDeliveryMethod: "SMS",
        },
    })

    const { mutate, isPending: isLoading } = useMutation({
        mutationKey: ["requestPin"],
        mutationFn: mutateRequestPin,
        onSuccess: (res) => {
            dispatch(setLoadingMaskStatus(false))
            const tempToken = localStorage.getItem("t3mp0")

            if (tempToken && res.code === 1105) {
                navigate(RoutesList.public.Pin)
                return
            }

            toast.error(
                language === "es"
                    ? "Error al solicitar el pin"
                    : "Error requesting pin"
            )
        },
        onError: (error) => {
            dispatch(setLoadingMaskStatus(false))
            toast.error(t.Errors.unexpectedError)
            console.error(error)
        },
    })

    const formHandler = (data: IPinPartialPayload) => {
        dispatch(setLoadingMaskStatus(true))
        mutate(data)
    }

    return (
        <Box
            sx={{
                display: "block",
                height: "fit-content",
                minHeight: "100vh",
                backgroundColor: grey[200],
                overflow: "auto",
                py: { xs: 0, sm: 4 },
            }}
        >
            <Container
                maxWidth="sm"
                sx={{
                    bgcolor: "background.paper",
                    borderRadius: { xs: 0, sm: 2 },
                    py: 3,
                }}
            >
                <Stack
                    direction="column"
                    spacing={3}
                    component={"form"}
                    onSubmit={handleSubmit(formHandler)}
                >
                    <Typography align="center" variant="h3" color="primary">
                        {t.Login.welcome}
                    </Typography>
                    <Typography align="center" variant="body1">
                        {t.Login.indentifyWithCuballama}
                    </Typography>

                    <Stack
                        spacing={1}
                        sx={{
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Stack
                            sx={{
                                width: "100%",
                                maxWidth: "325px",
                            }}
                        >
                            <Controller
                                control={control}
                                name="phoneNumber"
                                rules={{
                                    minLength: {
                                        value: 5,
                                        message:
                                            "El telefono debe tener al menos 5 números",
                                    },
                                    maxLength: {
                                        value: 20,
                                        message:
                                            "El máximo número de caracteres es 20",
                                    },
                                    required: "El campo es obligatorio",
                                    pattern: {
                                        value: /^\d+$/,
                                        message:
                                            "El campo debe contener sólo números",
                                    },
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <PhoneInput
                                        masks={{ ar: "(..) ........." }}
                                        enableSearch={true}
                                        country={country.toLowerCase()}
                                        value={value}
                                        disableSearchIcon={true}
                                        preferredCountries={["cu", "us", "es"]}
                                        specialLabel={"Numero de telefono"}
                                        onChange={onChange}
                                        isValid={
                                            errors.phoneNumber ? false : true
                                        }
                                        containerClass={
                                            errors.phoneNumber ? "error" : ""
                                        }
                                        disabled={isLoading}
                                        // searchPlaceholder={"Search"}
                                        // searchNotFound={t('not_found')}
                                        // localization={phoneLocales[baseLocale]}
                                    />
                                )}
                            />
                            {errors.phoneNumber && (
                                <Typography
                                    variant="body2"
                                    color="error.main"
                                    display="block"
                                    align="left"
                                    sx={{
                                        width: "100%",
                                        m: "3px 14px 0px 14px",
                                        lineHeight: 1.66,
                                    }}
                                >
                                    {errors.phoneNumber.message}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                    <Stack
                        spacing={1}
                        sx={{
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Stack>
                            <Controller
                                control={control}
                                name="captcha"
                                rules={{
                                    minLength: {
                                        value: 1,
                                        message:
                                            "Favor completar el campo de captcha",
                                    },
                                    required: "El campo es obligatorio",
                                }}
                                render={({ field: { onChange } }) => (
                                    <ReCAPTCHA
                                        ref={recaptchaRef as any}
                                        sitekey={
                                            DEFAULT_CONFIG.captcha_site_key
                                        }
                                        onChange={onChange}
                                    />
                                )}
                            />
                            {errors.captcha && (
                                <Typography
                                    variant="body2"
                                    color="error.main"
                                    display="block"
                                    align="left"
                                    sx={{
                                        width: "100%",
                                        m: "3px 14px 0px 14px",
                                        lineHeight: 1.66,
                                    }}
                                >
                                    {errors.captcha.message}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                    <LoadingButton
                        loading={isLoading}
                        type="submit"
                        variant="contained"
                        className="w-full"
                        sx={{ maxWidth: "325px", alignSelf: "center" }}
                        size="large"
                    >
                        {t.Login.loginAction}
                    </LoadingButton>
                    <Typography textAlign="center" variant="body1">
                        {t.Login.registerInvitation}
                    </Typography>
                </Stack>
            </Container>
        </Box>
    )
}

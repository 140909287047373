import { z } from "zod"
export interface IInviteFormData {
    userContact: string
    invitationType: string // SMS / WHATSAPP
}

export const validateInviteForm: (
    lang: string
) => z.ZodType<IInviteFormData> = (lang) =>
    z.lazy(() =>
        z.object({
            userContact: z
                .string()
                .min(
                    5,
                    lang === "ES"
                        ? "El teléfono debe tener al menos 5 números"
                        : "The phone number must have at least 5 numbers"
                ),
            invitationType: z.enum(["SMS", "WHATSAPP"]),
        })
    )

export const defaultInviteFormData: IInviteFormData = {
    userContact: "",
    invitationType: "SMS",
}

import en from "./languages/en"
import es from "./languages/es"
import { useSelector } from "react-redux"
import { getLanguage } from "component/language-picker/store/langSlice"

const language = {
    es,
    en,
}

function useTranslation() {
    const languageSelected = useSelector(getLanguage)

    return language[languageSelected]
}

export default useTranslation

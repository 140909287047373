import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import Divider from "@mui/material/Divider"
import DialogContent from "@mui/material/DialogContent"
import Typography from "@mui/material/Typography"
import DialogActions from "@mui/material/DialogActions"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import useHtmlElementClassObserver from "hooks/useHtmlClassObserver"
import { useEffect, useState } from "react"
import useTranslation from "translations/useTranslations"
import { useMediaQuery, useTheme } from "@mui/material"

const GoogleTranslateWarningModal = () => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
    const lang = useTranslation().Code
    const htmlElementClassNames = useHtmlElementClassObserver(
        document.documentElement
    )

    const [shouldShowModal, setShouldShowModal] = useState(false)

    useEffect(() => {
        const googleTranslateAlertWasDisplayed = localStorage.getItem(
            "googleTranslateAlertWasDisplayed"
        )
        if (googleTranslateAlertWasDisplayed === "true") return

        if (
            htmlElementClassNames.some((className) =>
                className.startsWith("translated")
            )
        ) {
            setShouldShowModal(true)
        }
    }, [htmlElementClassNames])

    const handleCloseAndAccept = () => {
        localStorage.setItem("googleTranslateAlertWasDisplayed", "true")
        setShouldShowModal(false)
    }

    const handleClose = (_: any, reason?: any) => {
        if (reason !== "backdropClick") {
            setShouldShowModal(false)
        }
    }
    return (
        <Dialog
            open={shouldShowModal}
            onClose={handleClose}
            fullWidth
            fullScreen={fullScreen}
            maxWidth="md"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle fontSize={"1.5rem"} id="alert-dialog-title">
                {lang === "ES"
                    ? "Problemas con google translate"
                    : "Google translate issues"}
            </DialogTitle>
            <Divider />
            <DialogContent>
                {lang === "ES" ? (
                    <Typography>
                        Debido a un problema en Google Chrome,
                        <span className="font-bold">
                            {" "}
                            no estamos pudiendo dar soporte a Google Translate.
                        </span>{" "}
                        Si continuas usando Google Translate,{" "}
                        <span className="font-bold">
                            puedes experimentar errores{" "}
                        </span>
                        al usar esta aplicación. Puedes desactivar Google
                        Translate o usar otro navegador si lo deseas. Disculpe
                        las molestias y estamos trabajando en resolver este
                        problema.
                    </Typography>
                ) : (
                    <Typography>
                        Due to a bug in Google Chrome,
                        <span className="font-bold">
                            {" "}
                            we are currently unable to support the Google
                            Translate
                        </span>{" "}
                        feature. If you choose to continue using Google
                        Translate,{" "}
                        <span className="font-bold">
                            you may experience errors{" "}
                        </span>
                        while using this application. You may choose to disable
                        Google Translate or use a different browser. We&apos;re
                        sorry for the inconvenience and are working to remedy
                        this issue.
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Box className="w-full p-2 justify-end flex">
                    <Button
                        variant="contained"
                        onClick={handleCloseAndAccept}
                        autoFocus
                    >
                        {lang === "ES" ? "Entiendo" : "I understand"}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default GoogleTranslateWarningModal

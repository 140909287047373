import React from "react"
import { ColumnDef } from "@tanstack/react-table"
import { IPromotor } from "./PromotorTypes"
import useTranslations from "translations"
import DateCell from "component/Table/Cells/DateCell"
import CopyLinkCellButton from "component/Table/Cells/CopyCellButton"

const UserPromotorsColumns = () => {
    const t = useTranslations().Promotors
    const columns = React.useMemo<ColumnDef<IPromotor>[]>(
        () => [
            {
                header: t.id,
                accessorKey: "userId",
                cell: (x) => (
                    <CopyLinkCellButton link={x.getValue() as any}>
                        {x.getValue() as any}
                    </CopyLinkCellButton>
                ),
            },
            {
                header: t.name,
                accessorKey: "name",
            },
            {
                header: t.referralId,
                accessorKey: "affiliateId",
            },
            {
                header: t.createdAt,
                accessorKey: "createdAt",
                enableColumnFilter: false,
                cell: (x) => <DateCell date={x.getValue() as any} />,
            },
            {
                header: t.updatedAt,
                accessorKey: "updatedAt",
                enableColumnFilter: false,
                cell: (x) => <DateCell date={x.getValue() as any} />,
            },
            {
                header: t.link,
                accessorKey: "referralLink",
                cell: (x) => (
                    <CopyLinkCellButton link={x.getValue() as any}>
                        {" "}
                        Link
                    </CopyLinkCellButton>
                ),
                enableColumnFilter: false,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    return { columns }
}

export default UserPromotorsColumns

import React, { useEffect, useState } from "react"

//Returns a country code based on cloudflares stack trace
const useCFCountryCode = () => {
    const [country, setCountry] = useState("")
    useEffect(() => {
        fetch("https://www.cloudflare.com/cdn-cgi/trace")
            .then((response) => {
                response.text().then((text) => {
                    let test = text.split("\n")
                    let resp: any = {}
                    test.forEach((item) => {
                        const split = item.split("=")
                        const key = split[0]
                        resp[key] = split[1]
                    })
                    if (resp.loc) {
                        setCountry(resp.loc)
                    } else {
                        setCountry("US")
                    }
                })
            })
            .catch((error) => {
                console.error(error)
                setCountry("US")
            })

        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { country }
}

export default useCFCountryCode

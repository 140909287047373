import Typography from "@mui/material/Typography"
import { format } from "date-fns"

const DateCell = ({ date, hideHours }: { date: Date; hideHours?: boolean }) => {
    return (
        <Typography>
            {format(date, hideHours ? "dd/MM/yy" : "dd/MM/yy hh:mm")}
        </Typography>
    )
}

export default DateCell

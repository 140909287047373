import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"

export default function LoadingMask() {
    return (
        <Backdrop
            sx={{
                zIndex: 99998,
            }}
            component="div"
            open={true}
        >
            <>
                <CircularProgress
                    color="inherit"
                    size={100}
                    sx={{
                        color: "primary.main",
                        zIndex: 99999,
                        position: "absolute",
                        top: "20%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                    }}
                />
            </>
        </Backdrop>
    )
}

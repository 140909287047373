import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import MenuIcon from "@mui/icons-material/Menu"
import AppBar from "@mui/material/AppBar"
import { iconColor, themeNavAndFooterBg } from "styles/MuiTheme"
import { Link } from "react-router-dom"
import { RoutesList } from "lib/RoutesList"
import LanguagePicker from "component/language-picker/LanguagePicker"
import { useSelector } from "react-redux"
import { selectThemeMode } from "styles/stylesSlice"
import Box from "@mui/material/Box"

export const NavDrawerHeaderMobile = ({
    open,
    setOpen,
    isAuthenticated,
}: {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    isAuthenticated: boolean
}) => {
    const themeMode = useSelector(selectThemeMode)
    const handleDrawerOpen = () => {
        setOpen(true)
    }
    return (
        <AppBar
            sx={{
                boxShadow: "none",
                display: { xs: "block", sm: "none" },
                backgroundColor: themeNavAndFooterBg(themeMode),
            }}
            position="absolute"
        >
            <Toolbar
                sx={{
                    justifyContent: "space-between",
                }}
            >
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        color: iconColor(themeMode),
                        marginRight: 5,
                        ...(open && { display: "none" }),
                    }}
                >
                    <MenuIcon />
                </IconButton>

                {!open && (
                    <Box
                        sx={{
                            width: "175px",
                        }}
                    >
                        <Link
                            to={isAuthenticated ? RoutesList.prv.Invite : "/"}
                        >
                            <img
                                width="130px"
                                height="auto"
                                src="/images/picture/logo-bo-cbll-restaurantes.svg"
                                alt={"logo"}
                            />
                        </Link>
                    </Box>
                )}

                <LanguagePicker />
            </Toolbar>
        </AppBar>
    )
}

import { IUser } from "utils/Types/AuthTypes"
import { create } from "zustand"

type authStatus = "loading" | "authorized" | "unauthorized"

interface IAuthStore {
    authStatus: authStatus
    setAuthStatus: (x: authStatus) => void
    user: IUser | null
    setUser: (x: IUser | null) => void
}

export const useAuthStore = create<IAuthStore>()((set) => ({
    user: null,
    authStatus: "loading",
    setAuthStatus: (state) =>
        set({
            authStatus: state,
        }),
    setUser: (state) =>
        set({
            user: state,
        }),
}))

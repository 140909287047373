import * as React from "react"
import { styled, useTheme } from "@mui/material/styles"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import { Link } from "react-router-dom"
import Box from "@mui/material/Box"
import NavDrawerIconsList from "./NavDrawerIconList"

const drawerWidth = 240
const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const NavDrawerMobileSidebar = ({
    open,
    setOpen,
}: {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const theme = useTheme()
    const handleDrawerClose = () => {
        setOpen(false)
    }
    return (
        <SwipeableDrawer
            PaperProps={{ sx: { width: drawerWidth } }}
            sx={{
                display: { xs: "block", sm: "none" },
            }}
            anchor="left"
            open={open}
            onClose={handleDrawerClose}
            onOpen={() => {}}
        >
            <DrawerHeader>
                <Box
                    sx={{
                        width: "150px",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Link to={"/"}>
                        <img
                            width="130"
                            height="auto"
                            src="/images/picture/logo-bo-cbll-restaurantes.svg"
                            alt={"logo"}
                        />
                    </Link>
                </Box>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === "rtl" ? (
                        <ChevronRightIcon />
                    ) : (
                        <ChevronLeftIcon />
                    )}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <NavDrawerIconsList open={open} setOpen={setOpen} />
        </SwipeableDrawer>
    )
}

export default NavDrawerMobileSidebar

import PhoneSharp from "@mui/icons-material/PhoneSharp"
import SmsSharp from "@mui/icons-material/SmsSharp"
import SupportAgent from "@mui/icons-material/SupportAgent"
import WhatsApp from "@mui/icons-material/WhatsApp"
import CloseOutlined from "@mui/icons-material/CloseOutlined"
import LoadingButton from "@mui/lab/LoadingButton"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import Slide from "@mui/material/Slide"
import Stack from "@mui/material/Stack"
import { mutateRequestPin } from "modules/login/loginQueries"
import React from "react"
import toast from "react-hot-toast"
import { useMutation } from "@tanstack/react-query"
import useTranslations from "translations"
import { IPinPartialPayload } from "utils/Types/LoginTypes"

const Transition = React.forwardRef(function Transition(props: any, ref) {
    return (
        <Slide direction="up" ref={ref} {...props}>
            {props.children}
        </Slide>
    )
})
interface props {
    open: boolean
    onClose: () => void
    loginData: IPinPartialPayload
    setTimer: React.Dispatch<React.SetStateAction<number | null>>
}

const ResendPinModal = ({ open, onClose, loginData, setTimer }: props) => {
    const translation = useTranslations()
    const { mutate: requestPin, isPending: isRequestingPin } = useMutation({
        mutationKey: ["requestPin"],
        mutationFn: mutateRequestPin,
        onSuccess: (res) => {
            const tempToken = localStorage.getItem("t3mp0")

            if (tempToken && res.code === 1105) {
                toast.success(
                    translation.Code === "ES" ? "Pin enviado" : "Pin sent"
                )
                //Reset timer on success
                setTimer(30)
                onClose()
                return
            }

            toast.error(
                translation.Code === "ES"
                    ? "Error al solicitar el pin"
                    : "Error requesting pin"
            )
        },
        onError: (error) => {
            console.error(error)
            toast.error(
                translation.Code === "ES"
                    ? "Error al solicitar el pin"
                    : "Error requesting pin"
            )
        },
    })

    const resendSmsCode = () => {
        const data: IPinPartialPayload = {
            ...loginData,
            captcha: null,
            pinDeliveryMethod: "SMS",
        }
        requestPin(data)
    }

    const getCodeByWhatsApp = () => {
        const data: IPinPartialPayload = {
            ...loginData,
            captcha: null,
            pinDeliveryMethod: "WHATSAPP",
        }
        requestPin(data)
    }

    const getCodeByCall = () => {
        const data: IPinPartialPayload = {
            ...loginData,
            captcha: null,
            pinDeliveryMethod: "CALL",
        }
        requestPin(data)
    }

    return (
        <Dialog
            disableEscapeKeyDown
            maxWidth="xs"
            TransitionComponent={Transition}
            fullWidth={true}
            open={open}
            onClose={onClose}
        >
            <div className="flex justify-between items-center">
                <DialogTitle>
                    {translation.Register.receivePinAgain}
                </DialogTitle>
                <IconButton sx={{ mr: 1 }} onClick={onClose}>
                    <CloseOutlined />
                </IconButton>
            </div>
            <DialogContent dividers>
                <Stack spacing={2}>
                    <Button
                        fullWidth
                        size="large"
                        variant="outlined"
                        onClick={resendSmsCode}
                        endIcon={<SmsSharp />}
                        disabled={isRequestingPin}
                    >
                        {translation.Register.receivePinAgain}
                    </Button>
                    <Button
                        fullWidth
                        size="large"
                        variant="outlined"
                        onClick={getCodeByCall}
                        endIcon={<PhoneSharp />}
                        disabled={isRequestingPin}
                    >
                        {translation.Register.receivePinPerCall}
                    </Button>
                    <Button
                        fullWidth
                        size="large"
                        variant="outlined"
                        onClick={getCodeByWhatsApp}
                        endIcon={<WhatsApp />}
                        disabled={isRequestingPin}
                    >
                        {translation.Register.receivePinByWhatsApp}
                    </Button>
                    <Button
                        fullWidth
                        size="large"
                        variant="outlined"
                        component="a"
                        className="resend_code"
                        endIcon={<SupportAgent />}
                        href="https://www.cuballama.com/contactar"
                        disabled={isRequestingPin}
                    >
                        {translation.Register.talkToAnAgent}
                    </Button>
                </Stack>
                <LoadingButton
                    sx={{ mt: 4 }}
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={onClose}
                    loading={isRequestingPin}
                >
                    {translation.Register.cancel}
                </LoadingButton>
            </DialogContent>
        </Dialog>
    )
}

export default ResendPinModal

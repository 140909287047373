import { useAuthStore } from "hooks/useAuthStore"
import { useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import { getUserData } from "./login/loginQueries"
import { getFromStorage } from "lib/Storage"
import { ISession } from "utils/Types/AuthTypes"
import { useLocation, useNavigate } from "react-router-dom"

const InitialDataLoader = () => {
    const { user, setUser, authStatus, setAuthStatus } = useAuthStore()
    const location = useLocation()
    const navigate = useNavigate()

    const { data: userQ } = useQuery({
        queryKey: ["userData", authStatus],
        queryFn: getUserData,
        refetchOnWindowFocus: false,
        enabled: authStatus === "authorized",
        gcTime: 0,
        staleTime: 0,
    })

    useEffect(() => {
        if (!userQ || user) return
        setUser(userQ)

        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userQ])

    //Check that token is still in storage evry time the app is loaded
    useEffect(() => {
        const tokenData = getFromStorage("token") as ISession | null

        if (tokenData?.access_token) {
            setAuthStatus("authorized")
        } else {
            setAuthStatus("unauthorized")
        }
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (
            location.pathname.startsWith("/prv") &&
            authStatus === "unauthorized"
        ) {
            navigate("/login")
        }

        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, authStatus])

    return null
}

export default InitialDataLoader

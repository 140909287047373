import { createSlice } from "@reduxjs/toolkit"
import { AppState } from "config/Store"
export type notificationType = "info" | "success" | "warning" | "error"

export interface CustomAlertProps {
    title: string
    message: string
    type?: notificationType
    showCancel?: boolean
    showHandlerAlert?: any
    confirm?: any
}

interface NotificationState {
    notification: string | null
    notificationType: "info" | "success" | "warning" | "error"
    alertProps: CustomAlertProps | null
}

const INITIAL_STATE: NotificationState = {
    notification: null,
    notificationType: "info",
    alertProps: null,
}

export const notificationSlice = createSlice({
    name: "notification",
    initialState: INITIAL_STATE,
    reducers: {
        setNotification: (
            state,
            action: {
                payload: { notification: string; type?: notificationType }
            }
        ) => {
            state.notification = action.payload.notification
            state.notificationType = action.payload.type || "info"
        },
        clearNotification: (state) => {
            state.notification = null
            state.notificationType = "info"
        },
        setAlert: (state, action) => {
            state.alertProps = action.payload
        },
    },
})

export const { setNotification, clearNotification, setAlert } =
    notificationSlice.actions

export const selectNotificationState = (state: AppState) => state.notification

export default notificationSlice

// const testServer = "https://api.test1.morfeo.cubatelefono.com"
//Test basic auth
// basic_auth_username: "mercadovip_web",
// basic_auth_password: "Y5vVfwdqGt3Qq69jFTvNLsryYXnyKNgl",
const backofficeSiteKey = "6LdJ8BcaAAAAAJzJF8qZG1pVP6bunuSVCJgRTAli"
// const backofficeProdSiteKey = "6LfDsRoaAAAAACBXlsdPRVZAaXd0kd6g8RJyzOzg"
const affilieProdAndStagingSiteKey = "6LfDsRoaAAAAACBXlsdPRVZAaXd0kd6g8RJyzOzg"

const prodServer = "https://api.cuballama.com"
const affiliatesProdUrl = "https://m2yfsvkup3.us-east-1.awsapprunner.com/api/v1"
//Runs in localhost
const DEV = {
    server: prodServer,
    captcha_site_key: backofficeSiteKey,
    basic_auth_username: "affiliates",
    basic_auth_password: "8039227006b7ce850a1cb99be5471e50",
    affiliates_server: affiliatesProdUrl,
}
//Servidor de staging
const TEST = {
    server: prodServer,
    captcha_site_key: affilieProdAndStagingSiteKey,
    basic_auth_username: "affiliates",
    basic_auth_password: "8039227006b7ce850a1cb99be5471e50",
    affiliates_server: affiliatesProdUrl,
}
const PROD = {
    server: prodServer,
    captcha_site_key: affilieProdAndStagingSiteKey,
    basic_auth_username: "affiliates",
    basic_auth_password: "8039227006b7ce850a1cb99be5471e50",
    affiliates_server: affiliatesProdUrl,
}

const config = () => {
    const env = import.meta.env.MODE

    if (env === "development") return DEV
    if (env === "test") return TEST

    return PROD
}

export const DEFAULT_CONFIG = config()

import { configureStore, combineReducers } from "@reduxjs/toolkit"
import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
    persistReducer,
    persistStore,
} from "redux-persist"
import { useDispatch } from "react-redux"
import storage from "redux-persist/lib/storage"
import langSlice from "component/language-picker/store/langSlice"
import stylesSlice from "styles/stylesSlice"
import { loaderMaskSlice } from "component/mask/store/maskSlice"
import notificationSlice from "modules/notification/store/notificationSlice"

const persistConfig = {
    key: "crm",
    storage,
}
const persistedLangReducer = persistReducer(persistConfig, langSlice.reducer)
const persistedThemeModeReducer = persistReducer(
    persistConfig,
    stylesSlice.reducer
)

const rootReducer = combineReducers({
    [langSlice.name]: persistedLangReducer,
    [loaderMaskSlice.name]: loaderMaskSlice.reducer,
    [notificationSlice.name]: notificationSlice.reducer,
    [stylesSlice.name]: persistedThemeModeReducer,
})

const store = configureStore({
    reducer: rootReducer,
    devTools: import.meta.env.MODE !== "production",
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
})

export type AppState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore["dispatch"]
export const setupStore = (preloadedState?: Partial<AppState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
    })
}
export const useAppDispatch: () => AppDispatch = useDispatch
export default store
export const persistor = persistStore(store)

import en from "./en"

const es: typeof en = {
    Code: "ES",
    OrderByCard: {
        filterTitle: "Datos de transacción",
    },
    Invite: {
        title: "Invitaciones",
        description:
            "Invita a tus amigos usando su número de teléfono, seleccione los canales para enviar las invitaciones.",
        button: "Enviar invitación",
        phonePlaceHolder: "Ingrese su número de teléfono",
        phoneLabel: "Número de teléfono",
        success: "Invitación enviada correctamente",
        error: "Error al enviar la invitación,favor inténtelo de nuevo",
    },
    Promotors: {
        title: "Promotores",
        id: "ID",
        name: "Nombre",
        referralId: "ID Referral",
        link: "Referral link",
        createdAt: "Fecha de creación",
        updatedAt: "Actualizado el",
        create: "Crear",
        createSuccess: "Promotor creado exitosamente",
        errorCreate: "Error creando promotor, por favor inténtalo de nuevo",
        errorUpdate: "Error editando promotor, por favor inténtalo de nuevo",
        updateSuccess: "Promotor editado exitosamente",
        update: "Editar",
        createPromotor: "Crear Promotor",
        editPromotor: "Editar Promotor",
        userId: "ID del Usuario",
    },
    Pagination: {
        labelRowsPerPage: "Elementos por página",
    },
    FormAddAddress: {
        addAddress: "Adicionar Dirección",
        updateAddress: "Actualizar Dirección",
    },
    AddressFields: {
        name: "Nombre",
        mainStreet: "Calle Principal",
        betweenStreet: "Entre",
        andStreet: "Y",
        building: "Edificio",
        stairs: "Escalera",
        number: "Número / Apartamento",
        province: "Provincia",
        municipality: "Municipio",
        locality: "Localidad",
    },
    Message: {
        authenticationFailure:
            "Autenticación vencida, inicie sesion para continuar.",
        confirmDeleteOffer: "¿Está seguro de que desea eliminar esta oferta?",
        confirmDeleteRestaurant:
            "¿Está seguro de que desea eliminar este negocio?",
        nonReversibleOperation: "Esta operación no se puede revertir",
        requiredFields: "Campos requeridos",
        from: "De",
        to: "A",
        confirmPublishOfferTitle: "Publicar Oferta",
        confirmPublishOffer:
            "Está apunto de publicar esta oferta, está seguro que es esta acción la que desea?",
        confirmDisapproveOfferTitle: "Desaprobar Oferta",
        confirmDisapproveOffer:
            "Está apunto de desaprobar esta oferta, está seguro que es esta acción la que desea?",
    },
    Actions: {
        new: "Nuevo",
        close: "Cerrar",
        accept: "Aceptar",
        cancel: "Cancelar",
        save: "Guardar",
        publish: "Publicar",
        disapprove: "Desaprobar",
    },
    BusinessData: {
        name: "Nombre",
        slug: "Slug",
        synthesis: "Síntesis",
        description: "Descripción",
        logo: "Logo",
        userRef: "Account ID",
        webSite: "Sitio Web",
        email: "Correo electrónico",
        billingEmail: "Correo de facturación",
        score: "Puntuación",
        deliveryTime: "Tiempo de entrega",
        deliveryTimeVSOpeningTime:
            "EL tiempo de entrega debe ser menor que el tiempo transcurrido entre la hora de apertura y la de cierre del negocio",
        numberOrdersAllowed: "Órdenes cada 30 min",
        open: "Hora de Apertura",
        close: "Hora de Cierre",
        includeDelivery: "Incluye entrega a domicilio",
        allowPickUp: "Recogida en negocio",
        allowCashPayment: "Pago en efectivo",
        allowMultiCartFlow: "Habilitar multi carrito",
        deliveryLimit: "Limite de entrega",
        averagePrice: "Rango de precios",
        timeMeasurementUnit: "Medida de tiempo",
        maximumDeliveryTime: "Tiempo máximo",
        cuisineTypes: "Tipos de cocina.",
        businessCategories: "Categorías de negocio",
        productTypes: "Tipos de productos",
        address: "Dirección",
        telephone: "Teléfono",
        mobile: "Celular",
        landline: "Línea fija",
        restaurantGeneralData: "Datos del negocio",
        restaurantGeneralDataAddress: "Dirección del negocio",
        scheduleAndDelivery: "Horario y reparto",
        availableDays: "Días de apertura",
        monday: "L",
        tuesday: "M",
        wednesday: "Mi",
        thursday: "J",
        friday: "V",
        saturday: "S",
        sunday: "D",
        mondayPlaceholder: "Lunes",
        tuesdayPlaceholder: "Martes",
        wednesdayPlaceholder: "Miércoles",
        thursdayPlaceholder: "Jueves",
        fridayPlaceholder: "Viernes",
        saturdayPlaceholder: "Sábado",
        sundayPlaceholder: "Domingo",
        unavailableDates: "Fechas de cierre",
        addUnavailableDate: "Adicionar fecha de cierre",
        deleteUnavailableDate: "Eliminar fecha de cierre",
        additionalInfo: "Información adicional",
    },
    Menu: {
        filterLabelOfferName: "Oferta",
        viewAll: "Todos",
        viewOnlyPending: "Pendientes",
        viewOnlyDisapproved: "Desaprobados",
    },
    Address: {
        mainStreet: "Calle Principal",
        betweenStreet: "Entre Calle",
        andStreet: "Y Calle",
        building: "Edificio",
        stairs: "Escalera",
        number: "Número / Apartamento",
        province: "Provincia",
        municipality: "Municipio",
        locality: "Localidad",
    },
    General: {
        viewAll: "Ver todos",
        viewRestaurants: "Ver Negocios",
        readFullArticle: "Read the full article",
        seeMore: "Ver más",
        notAvailable: "No disponible",
        noMatchesFoundFor: "No se han encontrado resultados para",
        tryAnotherSearchCriteria: "Prueba con otro criterio de búsqueda",
    },
    TopBar: {
        linkDelivery: "A domicilio",
        linkHelp: "Ayuda",
        linkHome: "Página Principal",
        linkRestaurant: "Negocios",
        linkReserve: "Reservar",
        btnLogin: "Regístrate o inicia sesión",
        btnLogout: "Cerrar Sesión",
        btnProfile: "Mi Cuenta",
        linkPersonalData: "Datos personales",
        linkPreferredAddresses: "Direcciones preferidas",
        linkPurchaseHistory: "Histórico de pedidos",
        btnRegister: "Crear cuenta",
        btnMyProfile: "Mi Perfil",
    },
    Header: {
        linkInvite: "Invitar",
        linkPromotors: "Promotores",
    },
    Home: {
        sectionHeaderTitle1: "Elige qué quieres comer hoy, ",
        sectionHeaderTitle2: "nosotros te lo llevamos",
        sectionHeaderSubtitle1: "Encuentra negocios cerca de ti",
        sectionHeaderSubtitle2: "Haz tu pedido",
        sectionHeaderSubtitle3: "Y disfruta de la comida",
        sectionHeaderHomeDeliveryOption: "Entrega a domicilio",
        sectionHeaderPickOrderOption: "Recoger pedido",
        sectionHeaderSearchButton: "Buscar",
        sectionFoodCategoryTitle: "¿Qué plan te apetece hoy?",
        sectionPopularsTitle: "Los más populares",
        sectionTrendingTitle: "Tendencias en La Habana",
        sectionSubscriptionTitle: "¡No te pierdas ni una!",
        sectionSubscriptionSubtitle:
            "Suscríbete a nuestro boletín para estar al día de las mejores promociones.",
        sectionSubscriptionButton: "Quiero suscribirme",
        sectionSubscriptionInputPlaceholder: "Correo",
        sectionPressTitle: "Cuballama Delivery en la prensa",
    },
    RestaurantActionBar: {
        buttonAdd: "Nuevo",
        filterName: "Nombre",
        filterStatus: "Estado",
        filterStatusOption1: "Todos",
        filterStatusOption2: "Publicados",
        filterStatusOption3: "No Publicados",
    },
    BusinessesList: {
        businesses: "negocios",
        eraseAll: "Borrar todo",
        filterDialogHeader: "Filtros",
        filterLabelBusinessName: "Negocios",
        filterTitle: "Estas filtrando por",
        noResult: "Sin resultados que mostrar",
        sectionFilterButton: "Filtrar",
        title: "Entrega a domicilio en La Habana",
    },
    Filter: {
        sort: "Ordenar",
        atHome: "A Domicilio",
        pickup: "Recogida",
        promotion: "Promoción",
        dietIntolerance: "Dieta/Intolerancia",
        cuisineType: "Tipo de cocina",
        eraseAll: "Borrar todos",
        apply: "Aplicar",
        deliverAt: "Entregar en",
        restaurantName: "Nombre del negocio",
        deliverTime: "Tiempo de entrega",
        bestScore: "Mejor puntuación",
        averagePrice: "Precio promedio",
    },
    validation: {
        required: "Campo requerido",
        integer: "No es un entero válido",
        number: "Número inválido",
        email: "Dirección de correo inválida",
        password: "La contraseña debe tener más de 6 caracteres",
        us_phone_number: "Número de teléfono inválido",
        date: "Fecha seleccionada inválida",
        length: "Mínimo número de caracteres mayor que",
        special_characters: "No se permiten caracteres especiales",
        form: "Existen errores en el formulario",
        incomplete_phone_number: "Número de teléfono incompleto",
        login: "Credenciales incorrectas",
    },
    Restaurant: {
        hours: "Horario",
        menuOfDay: "Menú del día",
        atHome: "A domicilio",
        pickup: "Recogida",
        thisIsYourRequest: "Este es su pedido",
        viewMyRequest: "Ver mi pedido",
        backToMenu: "Ir al Menú",
        completeRequest: "Completar Pedido",
        evaluations: "valoraciones",
        more: "Más",
    },
    Cart: {
        subtotal: "Subtotal",
        shipping: "Coste de envío",
        serviceTax: "Tarifa del servicio",
        total: "Total",
        completeRequest: "Completar pedido",
        myRequest: "Mi pedido",
    },
    Login: {
        info: "Accede a tu cuenta o crea una nueva para poder finalizar el proceso de pago.",
        welcome: "Bienvenid@",
        indentifyWithCuballama:
            "Introduce tu teléfono para entrar a tu cuenta.",
        header: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque egestas lorem quis quam suscipit ultricies sed aliquam et nunc vitae suscipit.",
        emailField: "Correo electrónico",
        emailHeader: "Correo electrónico del cliente",
        emailSubHeader:
            "Por favor introduzca una dirección de correo electrónico válida",
        backToLogin: "Volver a empezar",
        phoneNumberField: "Teléfono",
        passwordField: "Contraseña",
        pinHeader: "Validar teléfono",
        pinSubHeader:
            "Introduce el código de validación que te hemos enviado al",
        pin: "Pin",
        pinAction: "Validar código",
        pinMsg: "Se ha procedido a enviar un codigo PIN a su telefono",
        pinNotReceived: "No recibiste el código?",
        pinHelper: "Solo 4 caracteres",
        rememberMe: "Recuérdame",
        haveYouForgotPassword: "¿Has olvidado tu contraseña?",
        loginAction: "Entrar",
        registerInvitation: "Te enviaremos un SMS para verificar tu teléfono",
        createItHere: "Créala aquí",
        phonePlaceHolder: "Prefijo con teléfono",
        wait: "Espera",
        seconds: "segundos",
        resendCode: "Reenviar código",
    },
    Register: {
        createCuballamaAccount: "Crea una cuenta Cuballama",
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque egestas. lorem quis quam suscipit ultricies sed aliquam et nunc vitae suscipit.",
        info2: "En unos segundos recibirás tu PIN. Si has utilizado un teléfono móvil lo recibirás en un SMS, en caso contrario, por una llamada.",
        phoneField: "Teléfono",
        validateAction: "Validar Teléfono",
        alreadyRegistered: "¿Ya tienes una cuenta en Cuballama?",
        introPinOrGetACall:
            "Introduce el PIN que te hemos enviado por SMS o llamada al",
        modifyAction: "Modificar",
        pinReceived: "Pin recibido",
        password: "Contraseña",
        email: "Correo Electrónico",
        thisDataIsOptional: "Este dato es opcional",
        conditionPart1: "Al crear tu cuenta aceptas las",
        conditionPart2: "condiciones legales",
        conditionPart3: "y la",
        conditionPart4: "política de privacidad",
        conditionPart5: "de Cuballama",
        registerAction: "Crear Cuenta",
        resendCodeTitle: "¿No has recibido el PIN?",
        receivePinAgain: "Volver a recibir el PIN",
        receivePinByWhatsApp: "Recibir PIN por WhatsApp",
        receivePinPerCall: "Recibir PIN por llamada",
        talkToAnAgent: " Hablar con un agente",
        cancel: "Cancelar",
    },
    Payment: {
        viewMyRequest: "Ver mi pedido",
        thisIsMyRequest: "Este es tu pedido",
        haveAnyDiscount: "¿Tienes un cupón de descuento?",
        discountCode: "Código de descuento",
        apply: "Aplicar",
        subtotal: "Subtotal",
        shipping: "Coste de envío",
        taxes: "Tarifa de servicio",
        discount: "Descuento",
        total: "Total",
        modify: "Modificar",
        modifyRequest: "Modificar Pedido",
        continueCheckout: "Continuar con Checkout",
        deliveryData: "Datos de Entrega",
        pay: "Pagar",
        evaluations: "valoraciones",
        addressNotAvailable: "Dirección no disponible.",
        validatingAddress: "Validando dirección . . .",
        invalidDeliveryAddress:
            "La dirección seleccionada para esta entrega no es válida.",
        validDeliveryAddress:
            "La dirección seleccionada para esta entrega es válida.",
        setYourAddress: "Indicanos tu dirección",
        addressNewAddress: "Añadir nueva dirección",
        addDeliveryAddress: "Adicionar dirección de entrega",
        deliveryAddress: "Dirección de entrega",
        address: "Dirección",
        deliveryAddressHint:
            "Incluye el nombre y el número de tu calle, así como la zona o barrio",
        municipality: "Municipio",
        city: "Ciudad",
        additionalIndications: "Indicaciones adicionales",
        additionalIndicationsHint:
            "Por favor, añade cualquier indicación adicional que ayude al repartidor a encontrar tu dirección.",
        name: "Nombre",
        phone: "Teléfono",
        time: "Hora",
        deliveryDateAndTime: "Fecha y hora de entrega",
        clickToSelectDates: "Clic para seleccionar la fecha",
        deliveryWarning:
            "Asegúrese de que haya alguien para recepcionar el pedido en la fecha y hora señaladas.",
        confirmAndPay: "Confirmar y pagar",
        paymentInProgressMessage: "Pago en proceso, por favor espere",
        availableCredit: "Saldo disponible",
        makeYourPaymentsWithPaypal: "Realize su pago con Paypal",
        addCreditCard: "Adicionar Tarjeta",
        goBack: "Volver",
        asap: "Lo antes posible",
        contactData: "Datos de contacto",
        discountedBalance: "Saldo descontado",
        remainingBalance: "Saldo restante",
        transactionId: "Id Transacción",
        creditCard: "Tarjeta de Crédito",
        cart: "Carrito",
        paymentId: "Id de Pago",
        paymentDoneMessage: "Su pago ha sido realizado",
        paymentErrorMessage: "Su pago no se realizó debido al siguiente error",
        message: "Mensaje",
    },
    Profile: {
        personalData: "Datos Personales",
        logout: "Cerrar Sesión",
        cancel: "Cancelar",
        save: "Guardar",
        fullname: "Nombre y Apellidos",
        change: "Cambiar",
        email: "Correo Electrónico",
        passwordMustMatch: "Contraseña debe coincidir",
        mainPhone: "Teléfono Principal",
    },
    UserAddress: {
        editingAddress: "Editar dirección de entrega",
        deliveryAddress: "Nueva dirección de entrega",
        myUsualAddresses: "Mis direcciones habituales",
        addNew: "Añadir Nueva",
        noAddressesFound: "No tienes direcciones añadidas",
        tag: "Etiqueta",
        tagHint: "Identifica tu dirección (casa, trabajo,…)",
        mainStreet: "Calle Principal",
        typeToSearch: "Escriba para buscar...",
        typeToSearchHint:
            "Incluye el nombre y el número de tu calle; así como la zona o barrio ",
        betweenStreet: "Entre Calle",
        andStreet: "Y Calle",
        building: "Edificio",
        stairs: "Escalera",
        number: "Apartamento / Número",
        province: "Provincia",
        municipality: "Municipio",
        location: "Localidad",
        storeAddressInMyUsualAddresses:
            "Guardar esta dirección en mis direcciones habituales.",
        requiredFields: "Campos obligatorios",
        save: "Guardar",
        confirm: "Confirmar",
        loading: "Cargando",
        noMatchFound: "No se encontraron coincidencias",
        noMatchesFoundFor: "No se han encontrado resultados para",
        tryAnotherSearchCriteria: "Prueba con otro criterio de búsqueda",
        userAddressFormBtnCancel: "Cancelar",
        userAddressFormBtnSave: "Guardar",
        userAddressFormBtnConfirm: "Confirmar",
    },
    PurchaseHistory: {
        title: "Histórico de pedidos",
        mostRecentlyFirst: "Más actuales primero",
        oldestFirst: "Más antiguos primero",
        resumeRequest: "Resumen del pedido",
        total: "Total",
        purchaseAgain: "Volver a pedir",
        noPurchasesToShow: "No tiene compras realizadas",
    },
    Footer: {
        support: "Soporte",
        frequentQuestions: "Preguntas frecuentes",
        testimonials: "Testimonios",
        help: "Ayuda",
        legal: "Legal",
        termsOfUse: "Condiciones de uso",
        privacyPolicy: "Política de Privacidad",
        cookies: "Cookies",
        contact: "Contacto",
        copyright: "Cuballama  ·  Todos los derechos reservados",
    },
    Offer: {
        dialogTitleAdd: "Añadir Oferta",
        dialogTitleUpdate: "Actualizar Oferta",
        name: "Nombre",
        slug: "Slug",
        price: "Precio",
        hasStock: "Gestionar disponibilidad",
        availability: "Disponibilidad",
        days: "Días de entrega",
        description: "Descripción",
        classification: "Clasificación",
        additionListTitle: "Agregos",
        variantsListTitle: "Variantes",
        variantFormTitle: "Nueva variante",
        variantTypeFormTitle: "Nuevo tipo de variante",
        fieldLabelVariantType: "Tipo de variante",
        fieldLabelDescription: "Descripción",
        fieldLabelVariant: "Variante",
        fieldLabelPrice: "Precio",
        additionName: "Nombre",
        additionPrice: "Precio",
        measureId: "Unidad de medida",
        measureValue: "Valor de la unidad de medida",
        sku: "SKU",
    },
    PurchaseForm: {
        title: "Editar fecha y hora de entrega",
        fieldDate: "Fecha",
        fieldHour: "Hora",
    },
    EmailForm: {
        title: "Dirección de correo a notificar.",
        fieldEmail: "Correo",
    },
    Purchase: {
        filterClearButton: "Limpiar",
        filterRefreshButton: "Refrescar",
        filterMoreFiltersButton: "Más filtros",
        filterLessFiltersButton: "Menos filtros",
        filterTitle: "Filtros:",
        filterClient: "Cliente",
        filterClientHelperText: "(Cuballama, ACuba)",
        filterString: "General",
        filterStringHelperText: "(Destinatario, User id, Teléfono)",
        filterStatus: "Estado",
        filterPriority: "Prioridad",
        filterIncidentsStatus: "Estado de incidencia",
        filterOrderNumber: "Número de orden",
        filterRestaurantName: "Negocio",
        filterOrderRefund: "Reembolso",
        orderBy: "Ordenar por",
        labelRowsPerPage: "Pedidos por página",
        itemLabelOrder: "Orden",
        hintCurrency: "Moneda",
        itemLabelAccount: "Cuenta",
        itemLabelTotal: "Total",
        itemLabelCreateDate: "Creado",
        itemLabelDeliveryDate: "Entregado",
        itemLabelRestaurantName: "Negocio",
        itemLabelContactName: "Nombre",
        itemLabelContactPhone: "Teléfono",
        itemLabelSubtotal: "Subtotal",
        itemLabelPaidWithCard: "Pagado con tarjeta",
        itemLabelPaidWithTuBolsillo: "Pagado con saldo",
        itemLabelShipping: "Costo de envío",
        itemLabelDiscountCoupon: "Cupón de descuento",
        itemLabelDiscountAmount: "Descuento",
        itemLabelTaxes: "Tarifa de servicio",
        itemLabelAddress: "Dirección",
        itemLabelTransactionPaymentWayTypeName: "Tipo de pago",
        itemLabelTransactionUserCardMask: "Tarjeta",
        itemLabelTransactionPaymentMethodName: "Método de pago",
        itemLabelAddressDetail: "Detalle de dirección",
        itemLabelTransactionId: "Transacción",
        itemLabelStatusCompleted: "Completado",
        itemLabelStatusPreparing: "Preparando",
        itemLabelStatusCreated: "Creado",
        itemLabelStatusCanceled: "Cancelado",
        itemRefundTransactionId: "Transacción Devolución",
        itemRefund: "Devolución",
        itemRefundTransactionAmount: "Total",
        itemRefundTransactionAgent: "Agente",
        itemRefundTransactionDescription: "Descripción",
        actionCompleted: "Completar orden",
        actionPreparing: "Preparar orden",
        actionCanceled: "Cancelar orden",
        actionManageIncidences: "Gestionar incidencias",
        actionNotifyBusiness: "Notificar al negocio",
        actionNotifyClient: "Notificar al cliente",
        actionEditDate: "Editar fecha",
        actionRefund: "Realizar devolución",
        tabOrder: "Orden",
        tabTransaction: "Transacción",
        tabRefund: "Devolución",
        tabDevice: "Dispositivo",
        notifyRestaurantSuccess: "Ha sido notificado el negocio exitosamente.",
        notifyClientSuccess: "El cliente ha sido notificado exitosamente.",
        daily: "Diario",
        madeInApp: "Hecho en la App",
        madeInWeb: "Hecho en la Web",
    },
    Refund: {
        modalTitle: "Devolver Transacción",
        amount: "Monto",
        description: "Descripción",
        amountInvalid: "Monto no válido",
        descriptionInvalid: "Descripción no válida",
        refundSuccess: "Monto devuelto exitosamente",
        amountExceeded: "Monto para devolución excedido",
    },
    Incidents: {
        modalTitle: "Gestión de incidencias",
        labelAccountId: "Cuenta",
        labelOrderId: "Orden",
        labelRestaurant: "Negocio",
        labelTotal: "Total",
        fieldRestaurantResponsibility: "Responsabilidad del negocio",
        fieldDetailIncident: "Detalle",
        fieldAdjustmentType: "Tipo de ajuste",
        fieldAdjustment: "Ajuste",
        fieldPenaltyCharge: "Cargo de penalización",
        actionNotify: "Notificar",
        actionInProcess: "En seguimiento",
        actionSolve: "Resuelto",
        actionSolveWithAdjustment: "Resuelto con ajuste",
        validateTotalCost: "El ajuste no debe exceder el importe total",
    },
    Coupons: {
        filterTitle: "Filtrar por:",
        titleUpdate: "Editar Cupón",
        titleAdd: "Crear Cupón",
        labelCode: "Código",
        labelTotalAmount: "Monto total",
        labelAvailableAmount: "Monto disponible",
        labelUserRef: "ID de Usuario",
        labelAgentRef: "Agente",
        labelStartDate: "Fecha inicio",
        labelEndDate: "Fecha fin",
        labelEmail: "Correo",
        fieldCode: "Código",
        fieldTotalAmount: "Monto total",
        fieldUserRef: "ID de Usuario",
    },
    Role: {
        filterTitle: "Filtrar por:",
        titleUpdate: "Editar rol de usuario",
        titleAdd: "Crear rol de usuario",
        labelUserRef: "ID de Usuario",
        labelRoleId: "Rol",
        labelNickName: "Nombre",
        fieldName: "Nombre",
        fieldRole: "Rol",
        fieldUserRef: "ID de Usuario",
        confirmDeleteRole:
            "¿Está seguro de que desea eliminar este rol de usuario?",
    },
    Clients: {
        tabsPurchase: "Pedidos",
        tabsTransactions: "Transacciones",
        tabsDevices: "Dispositivos",
        sectionFilterTitle: "Filtrar por:",
        titleDialogFilter: "Filtro incial",
        labelFilterUserId: "ID de usuario del cliente",
        labelCreateDate: "Creado",
        labelTransactionTrace: "Detalle",
        labelErrorDescription: "Descripción del error",
        notAvailable: "No Disponible",
        noOrder: "Orden No.",
        cardMask: "Máscara Tarjeta",
        tpv: "Pasarela",
        transactionId: "Transacción",
        labelTransactionMessage: "Mensaje",
        labelTransactionAuthCode: "Código",
        labelDevicesIp: "IP",
        labelDevicesBrowser: "Navegador",
        labelDevicesVersion: "Versión",
        labelDevicesOS: "Sistema Operativo",
        labelDevicesDeviceId: "ID del dispositivo",
        labelDevicesMobileVendor: "Proveedor móvil",
        labelDevicesMobileModel: "Modelo de móvil",
        purchasesPaginationLabel: "Pedidos por página",
        transactionsPaginationLabel: "Transacciones por página",
        devicesPaginationLabel: "Dispositivos por página",
        defaultPaginationLabel: "Elementos por página",
        messageApproved: "Aprobado",
    },
    CurrencyExchange: {
        title: "Cambio de divisas",
        subtitle:
            "Servicio para el cálculo del cambio de moneda establecido en el sistema.",
        labelFrom: "De",
        labelTo: "A",
        labelAmount: "Monto",
        labelResult: "Resultado",
        button: "Calcular",
        infoRate1: "La tasa de cambio actual es de ",
        infoRate2: "por cada ",
    },
    Errors: {
        unexpectedError:
            "Se ha producido un error inesperado. Por favor, vuelva a intentarlo.",
    },
    NotFound: {
        title: "Página no encontrada",
        content:
            "La página solicitada no fue encontrada o ha sido cambiada. Por favor, vuelva a intentarlo desde el inicio. Si el problema persiste favor reportar el error.",
        btn: "Volver al inicio",
    },
}
export default es

import { setLoadingMaskStatus } from "component/mask/store/maskSlice"
import { IInviteFormData } from "./InviteValidations"
import axios, { AxiosResponse } from "axios"
import { DEFAULT_CONFIG } from "config/Config"
import { secureHeader } from "config/AxiosRequest"
import { IInviteResponse } from "./InviteTypes"

export async function sendInvite({
    body,
    dispatch,
}: {
    body: IInviteFormData
    dispatch: any
}): Promise<IInviteResponse | null> {
    dispatch(setLoadingMaskStatus(true))
    const response = (await axios.post(
        `${DEFAULT_CONFIG.affiliates_server}/invitations`,
        body,
        {
            ...secureHeader(),
        }
    )) as AxiosResponse<IInviteResponse>

    dispatch(setLoadingMaskStatus(false))

    if (response.status !== 200) return null

    return response.data
}

export default function PdfIcon({
    icon,
    width = 16,
    alt,
    classes = "",
    ext = "png",
}: {
    icon: any
    width?: number
    alt?: string
    classes?: string
    ext?: string
}) {
    return (
        <img
            className={classes}
            width={width}
            src={`./images/icons/${icon}.${ext}`}
            alt={alt}
        />
    )
}

import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import Divider from "@mui/material/Divider"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import { useMediaQuery, useTheme } from "@mui/material"
import { IPromotor, IPromotorFormData } from "./PromotorTypes"
import useTranslations from "translations"
import CloseOutlined from "@mui/icons-material/CloseOutlined"
import { useForm } from "react-hook-form"
import FormControlledTextInput from "component/form-controlled/FormControlledTextInput"
import {
    defaultPromotorFormData,
    validatePromotorForm,
} from "./PromotorsValidation"
import { zodResolver } from "@hookform/resolvers/zod"
import FormControlledNumberInput from "component/form-controlled/FormControlledNumberInput"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import {
    mutateCreateAffiliate,
    mutateUpdateAffiliate,
} from "./PromotorsQueries"
import toast from "react-hot-toast"
import LoadingButton from "@mui/lab/LoadingButton"
import slugify from "slugify"
import { useEffect } from "react"

const PromotorsFormModal = ({
    promotorData,
    onClose,
    open,
}: {
    promotorData: IPromotor | null
    open: boolean
    onClose: () => void
}) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
    const translation = useTranslations()
    const t = translation.Promotors
    const lang = translation.Code
    const queryClient = useQueryClient()

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<IPromotorFormData>({
        defaultValues: defaultPromotorFormData,
        resolver: zodResolver(validatePromotorForm(lang)),
    })

    const onCloseModal = () => {
        reset(defaultPromotorFormData)
        onClose()
    }

    useEffect(() => {
        if (!promotorData) return
        reset(promotorData)

        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [promotorData])

    const { mutate: create, isPending: isCreating } = useMutation({
        mutationKey: ["createAffiliate"],
        mutationFn: mutateCreateAffiliate,
        onSuccess: (res) => {
            if (res.status === 201) {
                toast.success(t.createSuccess)
                queryClient.invalidateQueries({ queryKey: ["getPromotors"] })
                onCloseModal()
                return
            }
            toast.error(t.errorCreate)
        },
        onError: (error: any) => {
            toast.error(promotorData ? t.errorUpdate : t.errorCreate)
            console.error(error)
        },
    })

    const { mutate: update, isPending: isUpdating } = useMutation({
        mutationKey: ["updateAffiliate"],
        mutationFn: mutateUpdateAffiliate,
        onSuccess: (res) => {
            if (res.status === 200) {
                toast.success(t.createSuccess)
                queryClient.invalidateQueries({ queryKey: ["getPromotors"] })
                onCloseModal()
                return
            }
            toast.error(t.errorCreate)
        },
        onError: (error: any) => {
            toast.error(promotorData ? t.errorUpdate : t.errorCreate)
            console.error(error)
        },
    })
    const submitFunc = (data: IPromotorFormData) => {
        if (promotorData) {
            update(data)
        } else {
            create(data)
        }
    }

    const isLoading = isCreating || isUpdating

    return (
        <Dialog
            open={open}
            onClose={onCloseModal}
            fullWidth
            fullScreen={fullScreen}
            maxWidth="sm"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                fontSize={"1.5rem"}
                sx={{ display: "flex", justifyContent: "space-between" }}
            >
                {promotorData ? t.editPromotor : t.createPromotor}
                <IconButton onClick={onCloseModal}>
                    <CloseOutlined />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Box
                    component="form"
                    onSubmit={handleSubmit(submitFunc)}
                    sx={{
                        gap: 2,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <FormControlledNumberInput
                        control={control}
                        errors={errors}
                        name="userId"
                        label={t.userId}
                        required={true}
                        isDisabled={!!promotorData}
                    />
                    <FormControlledTextInput
                        control={control}
                        errors={errors}
                        name="name"
                        label={t.name}
                        required={true}
                    />
                    <FormControlledTextInput
                        control={control}
                        errors={errors}
                        name="affiliateId"
                        label={t.referralId}
                        required={true}
                    />
                    {/* {promotorData && ( */}
                    {/*     <FormControlledTextInput */}
                    {/*         control={control} */}
                    {/*         errors={errors} */}
                    {/*         name="affiliateId" */}
                    {/*         label={t.referralId} */}
                    {/*     /> */}
                    {/* )} */}
                </Box>
            </DialogContent>
            <DialogActions>
                <Box className="w-full p-2 justify-end flex">
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        onClick={handleSubmit(submitFunc)}
                        autoFocus
                    >
                        {promotorData ? t.update : t.create}
                    </LoadingButton>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default PromotorsFormModal

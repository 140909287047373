import * as React from "react"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import NavDrawerHeaderDesktop from "./NavDrawerHeaderDesktop"
import NavDrawerDesktopSidebar from "./NavDrawerDesktopSidebar"
import NavDrawerMobileSidebar from "./NavDrawerMobileSidebar"
import { NavDrawerHeaderMobile } from "./NavDrawerHeaderMobile"
import { useAuthStore } from "hooks/useAuthStore"

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

export default function NavDrawer({ children }: { children: React.ReactNode }) {
    const [open, setOpen] = React.useState(false)
    const { authStatus } = useAuthStore()
    const isAuthenticated = authStatus === "authorized"

    return (
        <Box
            sx={{
                pl: { xs: 0, sm: 8 },
            }}
        >
            <NavDrawerHeaderDesktop
                open={open}
                isAuthenticated={isAuthenticated}
            />
            <NavDrawerHeaderMobile
                open={open}
                setOpen={setOpen}
                isAuthenticated={isAuthenticated}
            />
            <NavDrawerDesktopSidebar open={open} setOpen={setOpen} />
            <NavDrawerMobileSidebar open={open} setOpen={setOpen} />

            <Box component="main">
                <DrawerHeader />
                {children}
            </Box>
        </Box>
    )
}

import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import useTranslations from "translations"

export default function ErrorBoundry() {
    const t = useTranslations()
    return (
        <Container maxWidth="sm" sx={{ pt: 6 }}>
            <Stack
                spacing={6}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{ fontWeight: "bold", textAlign: "center" }}
                    variant="h3"
                >
                    {t.NotFound.title}
                </Typography>
                <img
                    alt="No encontrado"
                    width={190}
                    height={144}
                    src="/images/icons/notFound/no_results.svg"
                />
                <Typography sx={{ textAlign: "center", lineHeight: "22px" }}>
                    {t.NotFound.content}
                </Typography>
                <Button
                    component="a"
                    size="large"
                    href="/"
                    sx={{ maxWidth: "355px", width: "100%" }}
                    variant="outlined"
                >
                    {t.NotFound.btn}
                </Button>
            </Stack>
        </Container>
    )
}

import { useState, useEffect } from "react"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import useTranslations from "../../../../translations"
import ReactCodeInput from "react-code-input"
import { customEncode } from "../../../../utils/AuxiliarFunctions"
import _ from "lodash"
import { useNavigate } from "react-router-dom"
import { RoutesList } from "lib/RoutesList"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"
import { grey } from "@mui/material/colors"
import { Controller, useForm } from "react-hook-form"
import Chip from "@mui/material/Chip"
import LoadingButton from "@mui/lab/LoadingButton"
import toast from "react-hot-toast"
import { IVerifyPinPayload, mutateVerifyPin } from "modules/login/loginQueries"
import { useMutation } from "@tanstack/react-query"
import ResendPinModal from "./ResentPinModal"
import { IPinPartialPayload } from "utils/Types/LoginTypes"
import { addToStorage } from "lib/Storage"
import { useAuthStore } from "hooks/useAuthStore"

interface IPinFormData {
    pin: string
}

export const Pin = () => {
    const translation = useTranslations()
    const language = translation.Code
    const navigate = useNavigate()
    const { setAuthStatus } = useAuthStore()
    const [timer, setTimer] = useState<number | null>(30)
    const [openResendModal, setOpenResendModal] = useState(false)
    const [loginData, setLoginData] = useState<IPinPartialPayload | null>()

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IPinFormData>({
        defaultValues: {
            pin: "",
        },
    })

    useEffect(() => {
        if (!timer) return
        const timeInterval =
            timer > 0 && setInterval(() => setTimer((t) => t && t - 1), 1000)
        return () => {
            timeInterval && clearInterval(timeInterval)
        }
    }, [timer])

    useEffect(() => {
        const tempToken = localStorage.getItem("t3mp0")
        if (!tempToken) return navigate("/login")
        try {
            const tempData = JSON.parse(tempToken) as IPinPartialPayload
            setLoginData(tempData)
        } catch (error) {
            console.error(error)
            toast.error(
                language === "ES"
                    ? "Error al obtener los datos"
                    : "Error getting data"
            )
        }

        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { mutate: verifyPin, isPending: isVerifyingPin } = useMutation({
        mutationKey: ["verifyPin"],
        mutationFn: mutateVerifyPin,
        onSuccess: (res) => {
            if (res.access_token) {
                addToStorage("token", res)
                setAuthStatus("authorized")

                navigate(RoutesList.prv.Invite)
                localStorage.removeItem("t3mp0")
                return
            }

            //Unknown error
            toast.error(
                language === "ES"
                    ? "Error al validar el pin"
                    : "Error validating pin"
            )
        },

        onError: (error: any) => {
            //Wrong code
            if (error?.response?.data?.code === 1110) {
                toast.error(
                    language === "ES" ? "Código incorrecto" : "Incorrect code"
                )
                return
            }
            //Expired code
            if (error?.response?.data?.code === 1146) {
                toast.error(
                    language === "ES" ? "Código expirado" : "Expired code"
                )
                return
            }
            toast.error(
                language === "ES"
                    ? "Error al validar el pin"
                    : "Error validating pin"
            )
            console.error(error)
        },
    })

    const formHandler = (data: IPinFormData) => {
        const tempToken = localStorage.getItem("t3mp0")
        if (!tempToken) return navigate("/login")
        try {
            const tempData = JSON.parse(tempToken) as IPinPartialPayload

            let payload: IVerifyPinPayload = {
                username: tempData.phoneNumber,
                grant_type: "password",
                device_id: tempData.deviceId,
                passwordless_pin: data.pin,
                // channel: "MOBILE",
            }
            localStorage.setItem(
                "v4lP1",
                customEncode(payload.passwordless_pin) ?? ""
            )

            verifyPin({ DATA: payload })
        } catch (error) {
            console.error(error)
            toast.error(
                language === "ES"
                    ? "Error al obtener los datos"
                    : "Error getting data"
            )
        }
    }

    const onCloseResendModal = () => {
        setOpenResendModal(false)
    }

    return (
        <>
            {loginData && (
                <ResendPinModal
                    open={openResendModal}
                    onClose={onCloseResendModal}
                    loginData={loginData}
                    setTimer={setTimer}
                />
            )}
            <Box
                sx={{
                    display: "block",
                    height: "fit-content",
                    minHeight: "100vh",
                    backgroundColor: grey[200],
                    overflow: "auto",
                    py: { xs: 0, sm: 4 },
                }}
            >
                <Container
                    maxWidth="sm"
                    sx={{
                        bgcolor: "background.paper",
                        borderRadius: { xs: 0, sm: 2 },
                        py: 3,
                    }}
                >
                    <Stack direction="column" spacing={3}>
                        <Typography align="center" variant="h4" color="primary">
                            {translation.Login.pinHeader}
                        </Typography>
                        <Typography align="center" variant="body1">
                            {translation.Login.pinSubHeader}
                        </Typography>

                        <Stack
                            component="form"
                            alignItems="center"
                            spacing={3}
                            onSubmit={handleSubmit(formHandler)}
                            sx={{
                                width: "100%",
                                maxWidth: "450px",
                                mt: 2,
                                mx: "auto !important",
                            }}
                        >
                            <Chip
                                label={`+${loginData?.phoneNumber}`}
                                variant="outlined"
                                color="success"
                                onDelete={(e) => {
                                    e.preventDefault()
                                    localStorage.removeItem("t3mp0")
                                    navigate("/login")
                                }}
                                sx={{
                                    fontSize: "1.2rem",
                                }}
                            />
                            <Controller
                                control={control}
                                name="pin"
                                rules={{
                                    minLength: {
                                        value: 4,
                                        message:
                                            language === "ES"
                                                ? "El código debe tener al menos 4 caracteres"
                                                : "The code must have at least 4 characters",
                                    },
                                    maxLength: {
                                        value: 4,
                                        message:
                                            language === "ES"
                                                ? "El código puede tener máximo 4 caracteres"
                                                : "The code can have a maximum of 4 characters",
                                    },
                                    required:
                                        language === "ES"
                                            ? "El código es requerido"
                                            : "The code is required",
                                }}
                                render={({ field: { onChange } }) => (
                                    <>
                                        <ReactCodeInput
                                            name="pin"
                                            inputMode="numeric"
                                            fields={4}
                                            autoFocus={true}
                                            isValid={true}
                                            inputStyle={{
                                                fontFamily:
                                                    "Nunito Sans,sans-serif",
                                                borderRadius: "4px",
                                                borderStyle: "solid",
                                                borderWidth: "1px",
                                                boxShadow: "none",
                                                margin: "4px",
                                                width: "42px",
                                                height: "50px",
                                                fontSize: "32px",
                                                boxSizing: "border-box",
                                                textAlign: "center",
                                                outline: "none",
                                                padding: "0",
                                            }}
                                            inputStyleInvalid={{
                                                fontFamily:
                                                    "Nunito Sans,sans-serif",
                                                borderRadius: "4px",
                                                borderStyle: "solid",
                                                borderWidth: "1px",
                                                borderColor: "red",
                                                color: "red",
                                                boxShadow: "none",
                                                margin: "4px",
                                                width: "42px",
                                                height: "50px",
                                                fontSize: "32px",
                                                boxSizing: "border-box",
                                                textAlign: "center",
                                                outline: "none",
                                                padding: "0",
                                            }}
                                            onChange={(value) =>
                                                onChange(value)
                                            }
                                        />

                                        {errors.pin?.message && (
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    px: 2,
                                                    color: "error.main",
                                                }}
                                            >
                                                {errors?.pin?.message}
                                            </Typography>
                                        )}
                                    </>
                                )}
                            />

                            <LoadingButton
                                type="submit"
                                variant="contained"
                                fullWidth={true}
                                size="large"
                                loading={isVerifyingPin}
                                sx={{ maxWidth: "295px" }}
                            >
                                {translation.Login.pinAction}
                            </LoadingButton>

                            <Typography component="p">
                                {translation.Login.pinNotReceived}
                            </Typography>
                            {timer ? (
                                <Typography component="p">{`${translation.Login.wait} ${timer} ${translation.Login.seconds}`}</Typography>
                            ) : (
                                <Button
                                    fullWidth={true}
                                    size="large"
                                    variant="outlined"
                                    onClick={() => setOpenResendModal(true)}
                                    sx={{ maxWidth: "295px" }}
                                >
                                    {translation.Login.resendCode}
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </Container>
            </Box>
        </>
    )
}

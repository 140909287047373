import { useEffect } from "react"
import useTranslations from "../../translations"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { useSelector } from "react-redux"
import {
    CustomAlertProps,
    clearNotification,
    selectNotificationState,
} from "modules/notification/store/notificationSlice"
import { useAppDispatch } from "config/Store"

export const AlertManager = () => {
    const dispatch = useAppDispatch()
    const { alertProps } = useSelector(selectNotificationState) as {
        alertProps: CustomAlertProps | null
    }

    const MySwal = withReactContent(Swal)
    const translation = useTranslations()
    const showSwal = () => {
        if (!alertProps) return
        const { title, message, showCancel, type, confirm } = alertProps
        MySwal.fire({
            title: <div>{title}</div>,
            text: message,
            confirmButtonText: translation.Actions.accept,
            showCancelButton: showCancel,
            icon: type ?? "warning",
        }).then((result) => {
            if (result.isConfirmed && confirm) {
                return confirm()
            }
            return dispatch(clearNotification())
        })
    }

    useEffect(() => {
        if (alertProps) {
            showSwal()
        }

        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alertProps])
    return <></>
}

import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import { grey } from "@mui/material/colors"
import Typography from "@mui/material/Typography"
import useTranslations from "translations"
import TableContainer from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    flexRender,
} from "@tanstack/react-table"
import UserPromotorsColumns from "./PromotorsColumns"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import PromotorsFormModal from "./PromotorsFormModal"
import { useEffect, useState } from "react"
import { IPromotor } from "./PromotorTypes"
import PromotorsFilter from "./PromotorsFilter"
import { useQuery } from "@tanstack/react-query"
import { getPromotors } from "./PromotorsQueries"

const PromotorsPage = () => {
    const t = useTranslations().Promotors
    const { columns } = UserPromotorsColumns()
    const [openPromotorForm, setOpenPromotorForm] = useState(false)
    const [promoTorData, setPromoTorData] = useState<IPromotor | null>(null)
    const [tableData, setTableData] = useState<IPromotor[]>([])

    const { data } = useQuery({
        queryKey: ["getPromotors"],
        queryFn: getPromotors,
        gcTime: 0,
        staleTime: 0,
    })

    useEffect(() => {
        if (!data) return
        setTableData(data)

        return () => {}
    }, [data])

    const table = useReactTable({
        data: tableData,
        columns,
        // Pipeline
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        //
        // debugTable: true,
    })

    return (
        <>
            <PromotorsFormModal
                open={openPromotorForm}
                onClose={() => {
                    setOpenPromotorForm(false)
                    setPromoTorData(null)
                }}
                promotorData={promoTorData}
            />
            <Box
                sx={{
                    display: "block",
                    height: "fit-content",
                    minHeight: "100vh",
                    backgroundColor: grey[200],
                    overflow: "auto",
                    py: { xs: 0, sm: 2 },
                }}
            >
                <Container
                    sx={{
                        bgcolor: "background.paper",
                        borderRadius: { xs: 0, sm: 2 },
                        py: 3,
                    }}
                >
                    <Box
                        sx={{
                            justifyContent: "center",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        <Stack direction="row" gap={2}>
                            <Typography variant="h4">{t.title}</Typography>
                            <Button
                                onClick={() => setOpenPromotorForm(true)}
                                variant="outlined"
                            >
                                {t.create}
                            </Button>
                        </Stack>
                        <TableContainer component={Paper}>
                            <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    {table
                                        .getHeaderGroups()
                                        .map((headerGroup) => (
                                            <TableRow key={headerGroup.id}>
                                                {headerGroup.headers.map(
                                                    (header) => {
                                                        return (
                                                            <TableCell
                                                                key={header.id}
                                                                colSpan={
                                                                    header.colSpan
                                                                }
                                                            >
                                                                {header.isPlaceholder ? null : (
                                                                    <div>
                                                                        {flexRender(
                                                                            header
                                                                                .column
                                                                                .columnDef
                                                                                .header,
                                                                            header.getContext()
                                                                        )}
                                                                        {header.column.getCanFilter() ? (
                                                                            <div>
                                                                                <PromotorsFilter
                                                                                    column={
                                                                                        header.column
                                                                                    }
                                                                                    table={
                                                                                        table
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                )}
                                                            </TableCell>
                                                        )
                                                    }
                                                )}
                                            </TableRow>
                                        ))}
                                </TableHead>
                                <TableBody>
                                    {table.getRowModel().rows.map((row) => {
                                        return (
                                            <TableRow
                                                key={row.id}
                                                sx={{
                                                    "&:hover": {
                                                        backgroundColor:
                                                            grey[200],
                                                        cursor: "pointer",
                                                    },
                                                }}
                                                onClick={() => {
                                                    setOpenPromotorForm(true)
                                                    setPromoTorData(
                                                        row.original
                                                    )
                                                }}
                                            >
                                                {row
                                                    .getVisibleCells()
                                                    .map((cell) => {
                                                        return (
                                                            <TableCell
                                                                key={cell.id}
                                                            >
                                                                {flexRender(
                                                                    cell.column
                                                                        .columnDef
                                                                        .cell,
                                                                    cell.getContext()
                                                                )}
                                                            </TableCell>
                                                        )
                                                    })}
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default PromotorsPage

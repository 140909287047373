import { createTheme } from "@mui/material/styles"
import NunitoSans from "../fonts/nunito-sans/Nunito-Regular.ttf"

export const themeBg = (mode: "dark" | "light") =>
    mode === "dark" ? "#202028" : "white"

export const themeNavAndFooterBg = (mode: "dark" | "light") =>
    mode === "dark" ? "#171923" : "white"

export const themeText = (mode: "dark" | "light") =>
    mode === "dark" ? "#a3a3a3" : "black"

export const primaryText = (mode: "dark" | "light") =>
    mode === "dark" ? "#2D71D6" : "#34465d"

export const iconColor = (mode: "dark" | "light") =>
    mode === "dark" ? "#a9adc1" : "#34465d"

export const dividerColor = (mode: "dark" | "light") =>
    mode === "dark" ? "#a9adc1" : "#34465d"

export const theme = (mode: "dark" | "light") =>
    createTheme({
        palette: {
            mode: mode,
            background: {
                default: themeBg(mode),
                paper: themeBg(mode),
            },
            primary: {
                main: primaryText(mode),
            },
            secondary: {
                main: "#304582",
            },
            info: {
                main: "#428d44",
            },
            warning: {
                main: mode === "light" ? "#fb923c" : "#d18b30",
                contrastText: "#fff",
            },
        },

        typography: {
            h1: {
                fontFamily: "Nunito, Sans-serif",
            },
            h2: {
                fontFamily: "Nunito, Sans-serif",
            },
            h3: {
                fontFamily: "Nunito, Sans-serif",
            },
            h4: {
                fontFamily: "Nunito, Sans-serif",
            },
            h5: {
                fontFamily: "Nunito, Sans-serif",
                fontSize: "1.3rem",
                fontWeight: 600,
            },
            h6: {
                fontSize: "1.1rem",
                fontFamily: "Nunito, Sans-serif",
                fontWeight: 600,
            },
            //All text
            body1: {
                // fontWeight: 200,
                fontSize: "1rem",
                color: themeText(mode),
                opacity: mode === "dark" ? 0.87 : 1,
            },
            subtitle1: {
                fontWeight: 400,
            },
            //For prices
            subtitle2: {
                fontWeight: 400,
                // fontFamily: "monospace", //not accepted
            },
            // fontFamily: "Nunito, Sans-serif",
            button: {
                textTransform: "none",
            },
        },
        components: {
            // MuiSvgIcon: {
            //     styleOverrides: {
            //         root: {
            //             color: iconColor(mode),
            //         },
            //     },
            // },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        // background: "red",
                        // backgroundColor: themeBg(mode),
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        color: themeText(mode),
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    switchBase: {
                        // Controls default (unchecked) color for the thumb
                        color: "black",
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        // color: "red",
                    },
                },
            },

            MuiButton: {
                styleOverrides: {
                    root: {
                        color: mode === "dark" ? "white" : undefined,
                    },
                    textSecondary: {
                        color: "red",
                    },
                    sizeSmall: {
                        height: "33px",
                    },
                },
            },
            MuiCssBaseline: {
                styleOverrides: `
        @font-face {
          font-family: 'Nunito';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Nunito'), local('Nunito-Regular'), url(${NunitoSans}) format('ttf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
            },
        },
    })

import { z } from "zod"
import { IPromotorFormData } from "./PromotorTypes"

export const validatePromotorForm: (
    lang: string
) => z.ZodType<IPromotorFormData> = (lang) =>
    z.lazy(() =>
        z.object({
            userId: z
                .number()
                .min(
                    1,
                    lang === "ES"
                        ? "El teléfono debe tener al menos 5 números"
                        : "The phone number must have at least 5 numbers"
                ),
            name: z
                .string()
                .min(
                    1,
                    lang === "ES"
                        ? "El nombre es requerido"
                        : "Name is required"
                ),
            affiliateId: z.string(),
        })
    )

export const defaultPromotorFormData: IPromotorFormData = {
    userId: 0,
    name: "",
    affiliateId: "",
}

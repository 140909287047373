import _ from "lodash"
import pako from "pako"
import { sha256 } from "js-sha256"
import { Buffer } from "buffer"
import { IUser } from "./Types/AuthTypes"

export const customEncode = (data: any) => {
    let encoded = null
    try {
        let compress = pako.deflate(JSON.stringify(data), { gzip: true } as any)

        let buff = new Buffer(compress)
        let base64data = buff.toString("base64")
        let hash = sha256.hex(buff)
        encoded = base64data + "|" + hash
    } catch (err) {
        console.error(err)
    }
    return encoded
}

export const customDecode = (data: any) => {
    let decoded = null
    try {
        let body = data.split("|")
        let bufferFromBody = _base64ToArrayBuffer(body[0])
        let decompress = pako.inflate(bufferFromBody)
        let bufferFromDecompress = new Buffer(decompress)
        let info = bufferFromDecompress.toString("utf8")

        decoded = JSON.parse(info)
    } catch (err) {
        console.error(err)
    }
    return decoded
}

const _base64ToArrayBuffer = (base64: any) => {
    let binary_string = window.atob(base64)
    let len = binary_string.length
    let bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i)
    }
    return bytes.buffer
}

export const RoutesList = {
    public: {
        base: "/",
        Login: "/login",
        Pin: "/pin",
    },
    prv: {
        Invite: "/prv/invite",
        Promotors: "/prv/promotors",
    },
}

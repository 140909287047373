import { createSelector, createSlice } from "@reduxjs/toolkit"
import { AppState } from "config/Store"

export interface LangState {
    code: "es" | "en"
}

const INITIAL_STATE: LangState = {
    code: "es",
}

export const langSlice = createSlice({
    name: "lang",
    initialState: INITIAL_STATE,
    reducers: {
        setLanguage: (state, action) => {
            state.code = action.payload
        },
    },
})

export const { setLanguage } = langSlice.actions

export const selectLangState = (state: AppState) => state.lang

export const getLanguage = createSelector(
    selectLangState,
    (state) => state.code
)
export default langSlice

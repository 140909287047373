import { createSlice } from "@reduxjs/toolkit"
import { AppState } from "config/Store"

const INITIAL_STATE = {
    active: false,
}

export const loaderMaskSlice = createSlice({
    name: "mask",
    initialState: INITIAL_STATE,
    reducers: {
        setLoadingMaskStatus: (_, action: { payload: boolean }) => {
            return { active: action.payload }
        },
    },
})

export const { setLoadingMaskStatus } = loaderMaskSlice.actions

export const selectLoaderMaskState = (state: AppState) => state.mask.active

export default loaderMaskSlice

/* eslint-disable @typescript-eslint/ban-ts-comment */
import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"
import React from "react"
import type { Control, FieldErrors, FieldValues, Path } from "react-hook-form"
import { Controller } from "react-hook-form"

interface InputProps<T extends FieldValues> {
    autoFocus?: boolean
    control: Control<T>
    errors: FieldErrors<T>
    helperText?: string
    hidden?: boolean
    inputLeft?: any
    inputRight?: any
    isTextArea?: boolean
    label: string
    maxLength?: number
    name: Path<T>
    required?: boolean
    isDisabled?: boolean
    className?: string
    type?: string
    endAdornment?: React.ReactNode
}

const FormControlledTextInput = <T extends FieldValues>(
    props: InputProps<T>
) => {
    const {
        control,
        name,
        errors,
        label,
        helperText,
        maxLength,
        isTextArea,
        className,
        hidden,
        autoFocus,
        required,
        isDisabled,
        type,
        endAdornment,
    } = props

    const splitName = name.split(".")
    const reducedError = splitName.reduce((acc: any, curr: any) => {
        if (!acc[curr]) return acc
        if (isNaN(curr)) {
            return acc[curr]
        }
        return acc[parseInt(curr)]
    }, errors)

    const handleHelperText = () => {
        if (typeof reducedError?.message === "string") {
            return reducedError?.message
        }
        return helperText
    }

    return (
        <FormControl
            sx={{ display: hidden ? "none" : "flex" }}
            className={["w-full", className].join(" ")}
        >
            <Controller
                control={control}
                name={name}
                render={({ field }) => (
                    <TextField
                        label={label}
                        sx={{
                            "& input[type=number]": {
                                MozAppearance: "textfield",
                            },
                            "& input[type=number]::-webkit-outer-spin-button": {
                                WebkitAppearance: "none",
                                margin: 0,
                            },
                            "& input[type=number]::-webkit-inner-spin-button": {
                                WebkitAppearance: "none",
                                margin: 0,
                            },
                        }}
                        required={required}
                        disabled={isDisabled}
                        onWheelCapture={(e: any) => e.target.blur()}
                        type={type ?? "text"}
                        rows={isTextArea ? 4 : 1}
                        multiline={isTextArea}
                        value={field?.value ?? ""}
                        variant="outlined"
                        onChange={field.onChange}
                        autoFocus={autoFocus}
                        error={!!reducedError.message}
                        inputProps={{
                            maxLength: maxLength ?? undefined,
                            "data-testid": name,
                        }}
                        InputProps={{
                            endAdornment: endAdornment,
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        helperText={handleHelperText()}
                        FormHelperTextProps={{
                            //@ts-ignore  Si funciona
                            "data-testid": `${name}-helper-text`,
                        }}
                    />
                )}
            />
        </FormControl>
    )
}

export default FormControlledTextInput

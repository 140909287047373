import toast, { Toaster } from "react-hot-toast"

const Toasts = () => {
    return <Toaster />
}

export default Toasts

export const toastPromise = ({
    promise,
    lang,
    successEs,
    successEn,
    successCallback,
}: {
    promise: Promise<any>
    lang: "ES" | "EN"
    successEs: string
    successEn: string
    successCallback?: (x: any) => void
}) => {
    toast.promise(promise, {
        loading: lang === "ES" ? "Cargando..." : "Loading...",
        success: (data) => {
            successCallback && successCallback(data)
            return lang === "ES" ? successEs : successEn
        },

        error: () => {
            return lang === "ES"
                ? "Hubo un error, favor intente nuevamente"
                : "There was an error, please try again"
        },
    })
}

export default {
    Code: "EN",
    OrderByCard: {
        filterTitle: "Transaction info",
    },
    Invite: {
        title: "Invites",
        description:
            "Invite your friends using their phone number, select the channels you want to invite them through.",
        button: "Send invite",
        phonePlaceHolder: "Enter phone number",
        phoneLabel: "Phone number",
        success: "Invite sent successfully",
        error: "Error sending invite, please try again",
    },
    Promotors: {
        title: "Promotors",
        id: "ID",
        name: "Name",
        referralId: "ID Referral",
        link: "Referral link",
        createdAt: "Created at",
        updatedAt: "Updated at",
        create: "Create",
        createSuccess: "Promotor created successfully",
        errorCreate: "Error creating promotor, please try again",
        errorUpdate: "Error updating promotor, please try again",
        updateSuccess: "Promotor updated successfully",
        update: "Update",
        createPromotor: "Create Promotor",
        editPromotor: "Edit Promotor",
        userId: "User ID",
    },
    Pagination: {
        labelRowsPerPage: "Elements per page",
    },
    FormAddAddress: {
        addAddress: "Add Address",
        updateAddress: "Update Address",
    },
    AddressFields: {
        name: "Name",
        mainStreet: "Main Street",
        betweenStreet: "Between Street",
        andStreet: "And Street",
        building: "Building",
        stairs: "Stairs",
        number: "Number",
        province: "Province",
        municipality: "Municipality",
        locality: "Locality",
    },
    Message: {
        authenticationFailure: "Authentication expired, login to continue.",
        confirmDeleteOffer: "Are you sure you want to delete this offer?",
        confirmDeleteRestaurant:
            "Are you sure you want to delete this business?",
        nonReversibleOperation: "This is a non reversible operation",
        requiredFields: "Required Fields",
        from: "From",
        to: "To",
        confirmPublishOfferTitle: "Publish Offer",
        confirmPublishOffer:
            "You are about to make public this offer, are you sure of this action?",
        confirmDisapproveOfferTitle: "Disapprove Offer",
        confirmDisapproveOffer:
            "You are about to make disapprove this offer, are you sure of this action?",
    },
    Actions: {
        new: "New",
        close: "Close",
        accept: "Accept",
        cancel: "Cancel",
        save: "Save",
        publish: "Publish",
        disapprove: "Disapprove",
    },
    BusinessData: {
        name: "Name",
        slug: "Slug",
        synthesis: "Synthesis",
        description: "Description",
        logo: "Logo",
        userRef: "Account ID",
        webSite: "Website",
        email: "Email",
        billingEmail: "Billing Email",
        score: "Score",
        deliveryTime: "Delivery time",
        deliveryTimeVSOpeningTime:
            "The delivery time must be less than the time elapsed between the opening and closing time of the business.",
        numberOrdersAllowed: "Orders every 30 min",
        open: "Open time",
        close: "Close time",
        includeDelivery: "Includes home delivery",
        allowPickUp: "Business pick up",
        allowCashPayment: "Cash payment",
        allowMultiCartFlow: "Multi-cart flow",
        deliveryLimit: "Delivery limit",
        averagePrice: "Average price",
        timeMeasurementUnit: "Time measurement",
        maximumDeliveryTime: "Maximum time",
        cuisineTypes: "Cuisine types",
        businessCategories: "Business Categories",
        productTypes: "Product Types",
        address: "Address",
        telephone: "Telephone",
        mobile: "Mobile phone",
        landline: "Landline",
        restaurantGeneralData: "Bussiness data",
        restaurantGeneralDataAddress: "Bussiness address",
        scheduleAndDelivery: "Schedule and delivery",
        availableDays: "Available days",
        monday: "M",
        tuesday: "Tu",
        wednesday: "W",
        thursday: "Th",
        friday: "F",
        saturday: "Sa",
        sunday: "S",
        mondayPlaceholder: "Monday",
        tuesdayPlaceholder: "Tuesday",
        wednesdayPlaceholder: "Wednesday",
        thursdayPlaceholder: "Thursday",
        fridayPlaceholder: "Friday",
        saturdayPlaceholder: "Saturday",
        sundayPlaceholder: "Sunday",
        unavailableDates: "Unavailable dates",
        addUnavailableDate: "Add unavailable date",
        deleteUnavailableDate: "Delete unavailable date",
        additionalInfo: "Additional information",
    },
    Menu: {
        filterLabelOfferName: "offer",
        viewAll: "All",
        viewOnlyPending: "Pendings",
        viewOnlyDisapproved: "Disapproved",
    },
    Address: {
        mainStreet: "Main Street",
        betweenStreet: "Between Street",
        andStreet: "And Street",
        building: "Building",
        stairs: "Stairs",
        number: "Number",
        province: "Province",
        municipality: "Municipality",
        locality: "Locality",
    },
    General: {
        viewAll: "View all",
        viewRestaurants: "View Businesses",
        readFullArticle: "Read the full article",
        seeMore: "See more",
        notAvailable: "Not available",
        noMatchesFoundFor: "No matches found for",
        tryAnotherSearchCriteria: "try another search criteria",
    },
    TopBar: {
        linkDelivery: "Delivery",
        linkHelp: "Help",
        linkHome: "Home",
        linkRestaurant: "Business",
        linkReserve: "Reserve",
        btnLogin: "Sign up or sign in",
        btnLogout: "Logout",
        btnProfile: "My Account",
        linkPersonalData: "Personal data",
        linkPreferredAddresses: "Preferred addresses",
        linkPurchaseHistory: "Purchase history",
        btnRegister: "Create Account",
        btnMyProfile: "My Profile",
    },
    Header: {
        linkInvite: "Invite",
        linkPromotors: "Promotors",
    },
    Home: {
        sectionHeaderTitle1: "Choose what you want to eat today, ",
        sectionHeaderTitle2: "we will take it",
        sectionHeaderSubtitle1: "Find businesses near you",
        sectionHeaderSubtitle2: "Place your order",
        sectionHeaderSubtitle3: "And enjoy the food",
        sectionHeaderHomeDeliveryOption: "Home Delivery",
        sectionHeaderPickOrderOption: "Pick Order",
        sectionHeaderSearchButton: "Search",
        sectionFoodCategoryTitle: "What plan do you fancy today?",
        sectionPopularsTitle: "The most popular",
        sectionTrendingTitle: "Trends in Havana",
        sectionSubscriptionTitle: "Don't miss anything!",
        sectionSubscriptionSubtitle:
            "Subscribe to our newsletter to keep up to date with the best promotions.",
        sectionSubscriptionButton: "I want to subscribe",
        sectionSubscriptionInputPlaceholder: "Email",
        sectionPressTitle: "Cuballama Delivery in the press",
    },
    RestaurantActionBar: {
        buttonAdd: "New",
        filterName: "Name",
        filterStatus: "Status",
        filterStatusOption1: "All",
        filterStatusOption2: "Published",
        filterStatusOption3: "No Published",
    },
    BusinessesList: {
        businesses: "businesses",
        eraseAll: "Erase all",
        filterDialogHeader: "Filter",
        filterLabelBusinessName: "Businesses",
        filterTitle: "You are filtering by",
        noResult: "No results to show",
        sectionFilterButton: "Filter",
        title: "Home delivery in Havana",
    },
    Filter: {
        sort: "Sort",
        atHome: "At home",
        pickup: "Pickup",
        promotion: "Promotion",
        dietIntolerance: "Diet/Intolerance",
        cuisineType: "Cuisine type",
        eraseAll: "Erase all",
        apply: "Apply",
        deliverAt: "Deliver at",
        restaurantName: "Business name",
        deliverTime: "Deliver time",
        bestScore: "Best score",
        averagePrice: "Average price",
    },
    validation: {
        required: "Required field",
        integer: "Not a valid number",
        number: "Not valid number",
        email: "Wrong email",
        password: "Password must be 6 characters or more",
        us_phone_number: "Invalid phone number",
        date: "Invalid date",
        length: "Minimun characters must be above of",
        special_characters: "Special characters not allowed",
        form: "There are validation errors in the form",
        incomplete_phone_number: "Incomplete phone number",
        login: "Invalid credentials",
    },
    Restaurant: {
        hours: "Hours",
        menuOfDay: "Menu of the day",
        atHome: "At home",
        pickup: "Pickup",
        thisIsYourRequest: "This is your request",
        viewMyRequest: "View my request",
        backToMenu: "Back to Menu",
        completeRequest: "Complete Request",
        evaluations: "evaluations",
        more: "More",
    },
    Cart: {
        subtotal: "Subtotal",
        shipping: "Shipping",
        serviceTax: "Service Tax",
        total: "Total",
        completeRequest: "Complete request",
        myRequest: "My Request",
    },
    Login: {
        info: "Enter to your account or create a new one so you can finish your payment.",
        welcome: "Welcome",
        indentifyWithCuballama: "Enter your phone to enter your account.",
        header: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque egestas lorem quis quam suscipit ultricies sed aliquam et nunc vitae suscipit.",
        emailField: "Email",
        emailHeader: "Customer email",
        emailSubHeader: "Please enter a valid email address",
        backToLogin: "Go back to start",
        phoneNumberField: "Phone",
        passwordField: "Password",
        pinHeader: "Validate phone number",
        pinSubHeader:
            "Introduce the validation code that we sent to your phone",
        pin: "Pin",
        pinAction: "Done",
        pinMsg: "a PIN number has been sent to your phone",
        pinNotReceived: "Didn't you get the code?",
        pinHelper: "Only 4 characters",
        rememberMe: "Remember me",
        haveYouForgotPassword: "Have you forgotten your password?",
        loginAction: "Log in",
        registerInvitation: "We will send you an SMS to verify your phone",
        createItHere: "Create it here",
        phonePlaceHolder: "Phone with prefix",
        wait: "Wait",
        seconds: "seconds",
        resendCode: "Resend code",
    },
    Register: {
        createCuballamaAccount: "Create a Cuballama Account",
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque egestas. lorem quis quam suscipit ultricies sed aliquam et nunc vitae suscipit.",
        info2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque egestas. lorem quis quam suscipit ultricies sed aliquam et nunc vitae suscipit.",
        phoneField: "Phone",
        validateAction: "Validate Phone",
        alreadyRegistered: "Already registered in Cuballama?",
        introPinOrGetACall:
            "Enter the PIN that we have sent to you via SMS o call to",
        modifyAction: "Modify",
        pinReceived: "Pin received",
        password: "Password",
        email: "Email",
        thisDataIsOptional: "This data is optional",
        conditionPart1: "Creating this account means that you accept",
        conditionPart2: "legal conditions",
        conditionPart3: "and the",
        conditionPart4: "policy of privacy",
        conditionPart5: "of Cuballama",
        registerAction: "Create Account",
        resendCodeTitle: "You have not received the PIN?",
        receivePinAgain: "Receive the PIN again",
        receivePinByWhatsApp: "Receive PIN by WhatsApp",
        receivePinPerCall: "Receive PIN per call",
        talkToAnAgent: "Talk to an agent",
        cancel: "Cancel",
    },
    Payment: {
        viewMyRequest: "View my request",
        thisIsMyRequest: "This is my request",
        haveAnyDiscount: "Do you have any discount?",
        discountCode: "Discount code",
        apply: "Apply",
        subtotal: "Subtotal",
        shipping: "Shipping",
        taxes: "Taxes",
        discount: "Discount",
        total: "Total",
        modify: "Modify",
        modifyRequest: "Modify Request",
        continueCheckout: "Continue to Checkout",
        deliveryData: "Delivery Data",
        pay: "Pay",
        evaluations: "evaluations",
        addressNotAvailable: "Address not available.",
        validatingAddress: "Validating address . . .",
        invalidDeliveryAddress: "Invalid address for this delivery.",
        validDeliveryAddress: "This is a valid address for this delivery.",
        setYourAddress: "Tell us your address",
        addressNewAddress: "Add new address",
        addDeliveryAddress: "Add delivery address",
        deliveryAddress: "Delivery address",
        address: "Address",
        deliveryAddressHint:
            "Include your street name and number, as well as your neighborhood or area",
        municipality: "Municipality",
        city: "City",
        additionalIndications: "Additional indications",
        additionalIndicationsHint:
            "Please, add any additional indications that may help the delivery drivers to find your address.",
        name: "Name",
        phone: "Phone",
        time: "Time",
        deliveryDateAndTime: "Date and time of delivery",
        clickToSelectDates: "Click to select the date",
        deliveryWarning:
            "Make sure that there will be a person to receive your request at the selected date and time.",
        confirmAndPay: "Confirm and pay",
        paymentInProgressMessage: "Payment in progress, please wait",
        availableCredit: "Available credit",
        makeYourPaymentsWithPaypal: "Make your payment with Paypal",
        addCreditCard: "Add CreditCard",
        goBack: "Back",
        asap: "As soon as posible",
        contactData: "Contact data",
        discountedBalance: "Discounted balance",
        remainingBalance: "Remaining Balance",
        transactionId: "Transaction ID",
        creditCard: "Credit Card",
        cart: "Cart",
        paymentId: "Payment ID",
        paymentDoneMessage: "Your payment is done",
        paymentErrorMessage:
            "Your payment did not finish because an error ocurr",
        message: "Message",
    },
    Profile: {
        personalData: "Personal Data",
        logout: "Logout",
        cancel: "Cancel",
        save: "Save",
        fullname: "Full name",
        change: "Change",
        email: "Email",
        passwordMustMatch: "Password must match",
        mainPhone: "Main Phone",
    },
    UserAddress: {
        editingAddress: "Edit delivery address",
        deliveryAddress: "New delivery address",
        myUsualAddresses: "My Usual Addresses",
        addNew: "Add New",
        noAddressesFound: "No addresses to show",
        tag: "Tag",
        tagHint: "Specify your address (home, work,…)",
        mainStreet: "Main Street",
        typeToSearch: "Type to search...",
        typeToSearchHint:
            "Include your street name and number; also your location or neighborhood ",
        betweenStreet: "Between Street",
        andStreet: "And Street",
        building: "Building",
        stairs: "Stair",
        number: "Apartment / Number",
        province: "Province",
        municipality: "Municipality",
        location: "Location",
        storeAddressInMyUsualAddresses:
            "Save this address in my usual directions.",
        requiredFields: "Required Fields",
        save: "Save",
        confirm: "Confirm",
        loading: "Loading",
        noMatchFound: "No matches found",
        noMatchesFoundFor: "No matches found for",
        tryAnotherSearchCriteria: "try another search criteria",
        userAddressFormBtnCancel: "Cancel",
        userAddressFormBtnSave: "Save",
        userAddressFormBtnConfirm: "Confirm",
    },
    PurchaseHistory: {
        title: "Purchase History",
        mostRecentlyFirst: "Most Recent First",
        oldestFirst: "Oldest First",
        resumeRequest: "Resume Request",
        total: "Total",
        purchaseAgain: "Purchase Again",
        noPurchasesToShow: "No purchases to show",
    },
    Footer: {
        support: "Support",
        frequentQuestions: "Frequent questions",
        testimonials: "Testimonials",
        help: "Help",
        legal: "Legal",
        termsOfUse: "Terms of use",
        privacyPolicy: "Privacy Policy",
        cookies: "Cookies",
        contact: "Contact",
        copyright: "Cuballama  ·  All rights reserved",
    },
    Offer: {
        dialogTitleAdd: "Add Offer",
        dialogTitleUpdate: "Update Offer",
        name: "Name",
        slug: "Slug",
        price: "Price",
        hasStock: "Manage availability",
        availability: "Availability",
        days: "Delivery days",
        description: "Description",
        classification: "Classification",
        additionListTitle: "Additions",
        variantsListTitle: "Variants",
        variantFormTitle: "New variant",
        variantTypeFormTitle: "New variant type",
        fieldLabelVariantType: "Variant type",
        fieldLabelDescription: "Description",
        fieldLabelVariant: "Variant",
        fieldLabelPrice: "Price",
        additionName: "Name",
        additionPrice: "Price",
        measureId: "Measure unit",
        measureValue: "Measure value",
        sku: "SKU",
    },
    PurchaseForm: {
        title: "Edit delivery date and time",
        fieldDate: "Date",
        fieldHour: "Hour",
    },
    EmailForm: {
        title: "Email address to notify.",
        fieldEmail: "Email",
    },
    Purchase: {
        filterMoreFiltersButton: "More filters",
        filterRefreshButton: "Refresh",
        filterClearButton: "Clear",
        filterLessFiltersButton: "Less filters",
        filterTitle: "Filters:",
        filterClient: "Client",
        filterClientHelperText: "(Cuballama, ACuba)",
        filterString: "General",
        filterStringHelperText: "(Recipient, User id, Telephone)",
        filterStatus: "Status",
        filterPriority: "Priority",
        filterIncidentsStatus: "Incidence Status",
        filterOrderNumber: "Order number",
        filterRestaurantName: "Business",
        filterOrderRefund: "Refund",
        orderBy: "Order by",
        labelRowsPerPage: "Purchases per page",
        itemLabelOrder: "Order",
        hintCurrency: "Currency",
        itemLabelAccount: "Account",
        itemLabelTotal: "Total",
        itemLabelCreateDate: "Created",
        itemLabelDeliveryDate: "Delivered",
        itemLabelRestaurantName: "Business",
        itemLabelContactName: "Name",
        itemLabelContactPhone: "Phone",
        itemLabelSubtotal: "Subtotal",
        itemLabelPaidWithCard: "Paid with card",
        itemLabelPaidWithTuBolsillo: "Paid with balance",
        itemLabelShipping: "Shipping",
        itemLabelDiscountCoupon: "Discount coupon",
        itemLabelDiscountAmount: "Discount amount",
        itemLabelTaxes: "Taxes",
        itemLabelAddress: "Address",
        itemLabelAddressDetail: "Address detail",
        itemLabelTransactionId: "Transaction",
        itemLabelStatusCompleted: "Completed",
        itemLabelTransactionPaymentMethodName: "Payment method",
        itemLabelTransactionPaymentWayTypeName: "Payment type",
        itemLabelTransactionUserCardMask: "Card",
        itemLabelStatusPreparing: "Preparing",
        itemLabelStatusCreated: "Created",
        itemLabelStatusCanceled: "Canceled",
        itemRefundTransactionId: "Refund transaction",
        itemRefund: "Refund",
        itemRefundTransactionAmount: "Total",
        itemRefundTransactionAgent: "Agent",
        itemRefundTransactionDescription: "Description",
        actionCompleted: "Complete order",
        actionPreparing: "Prepare order",
        actionCanceled: "Cancel order",
        actionManageIncidences: "Manage Incidences",
        actionNotifyBusiness: "Notify to business",
        actionNotifyClient: "Notify to client",
        actionEditDate: "Edit date",
        actionRefund: "Make refund",
        tabOrder: "Order",
        tabTransaction: "Transaction",
        tabRefund: "Refund",
        tabDevice: "Device",
        notifyRestaurantSuccess: "The business has been successfully notified.",
        notifyClientSuccess: "The client has been successfully notified.",
        daily: "Daily",
        madeInApp: "Made in App",
        madeInWeb: "Made in Web",
    },
    Refund: {
        modalTitle: "Refund Transaction",
        amount: "Amount",
        description: "Description",
        amountInvalid: "Invalid amount",
        descriptionInvalid: "Invalid description",
        refundSuccess: "Refunded successfully",
        amountExceeded: "Refund amount has been exceeded",
    },
    Incidents: {
        modalTitle: "Management of incidents",
        labelAccountId: "Account",
        labelOrderId: "Order",
        labelRestaurant: "Business",
        labelTotal: "Total",
        fieldRestaurantResponsibility: "Business responsibility",
        fieldDetailIncident: "Detail",
        fieldAdjustmentType: "Adjustment type",
        fieldAdjustment: "Adjustment",
        fieldPenaltyCharge: "Penalty charge",
        actionNotify: "Notify",
        actionInProcess: "In process",
        actionSolve: "Solve",
        actionSolveWithAdjustment: "Solve with adjustment",
        validateTotalCost: "The adjustment must not exceed the total amount",
    },
    Coupons: {
        filterTitle: "Filter by:",
        titleUpdate: "Edit Coupon",
        titleAdd: "Create Coupon",
        labelCode: "Code",
        labelTotalAmount: "Total Amount",
        labelAvailableAmount: "Available Amount",
        labelUserRef: "User ID",
        labelAgentRef: "Agent",
        labelStartDate: "Start date",
        labelEndDate: "End date",
        labelEmail: "Email",
        fieldCode: "Code",
        fieldTotalAmount: "Total Amount",
        fieldUserRef: "User ID",
    },
    Role: {
        filterTitle: "Filter by:",
        titleUpdate: "Edit user role",
        titleAdd: "Create user role",
        labelUserRef: "User ID",
        labelRoleId: "Role",
        labelNickName: "Name",
        fieldName: "Name",
        fieldRole: "Role",
        fieldUserRef: "User ID",
        confirmDeleteRole: "Are you sure you want to delete this user role?",
    },
    Clients: {
        tabsPurchase: "Purchase",
        tabsTransactions: "Transactions",
        tabsDevices: "Devices",
        sectionFilterTitle: "Filter by:",
        titleDialogFilter: "Initial filter",
        labelFilterUserId: "Client user ID",
        labelCreateDate: "Created",
        labelTransactionTrace: "Detailed message",
        labelErrorDescription: "Error Description",
        notAvailable: "Not Available",
        noOrder: "Orden No.",
        cardMask: "Máscara Tarjeta",
        tpv: "Pasarela",
        transactionId: "Transacción",
        labelTransactionMessage: "Message",
        labelTransactionAuthCode: "Code",
        labelDevicesIp: "IP",
        labelDevicesBrowser: "Browser",
        labelDevicesVersion: "Version",
        labelDevicesOS: "Operative System",
        labelDevicesDeviceId: "Device ID",
        labelDevicesMobileVendor: "Mobile vendor",
        labelDevicesMobileModel: "Mobile model",
        purchasesPaginationLabel: "Purchases per page",
        transactionsPaginationLabel: "Transactions per page",
        devicesPaginationLabel: "Devices per page",
        defaultPaginationLabel: "Elements per page",
        messageApproved: "Approved",
    },
    CurrencyExchange: {
        title: "Currency exchange",
        subtitle:
            "Service for the calculation of the currency exchange established in the system.",
        labelFrom: "From",
        labelTo: "To",
        labelAmount: "Amount",
        labelResult: "Result",
        button: "Calculate",
        infoRate1: "The current exchange rate is ",
        infoRate2: "for each ",
    },
    Errors: {
        unexpectedError: "An unexpected error has occurred. Please try again.",
    },
    NotFound: {
        title: "Page not found",
        content:
            "The requested page could not be found or has been chenged. Please try again from home or report this error if it persists.",
        btn: "Go back home",
    },
}

import NavDrawer from "component/Drawer/NavDrawer"
import Mask from "component/mask/Mask"
import { selectLoaderMaskState } from "component/mask/store/maskSlice"
import InitialDataLoader from "modules/InitialDataLoader"
import { useSelector } from "react-redux"
import { Outlet } from "react-router-dom"

const RootLayout = () => {
    const loaderMaskStatus = useSelector(selectLoaderMaskState)
    return (
        <main className="min-h-[100dvh] relative">
            <InitialDataLoader />
            <Mask active={loaderMaskStatus}>
                <section className="pb-[50px]">
                    <NavDrawer>
                        <Outlet />
                    </NavDrawer>
                </section>
            </Mask>
        </main>
    )
}

export default RootLayout

import Toolbar from "@mui/material/Toolbar"
import { styled } from "@mui/material/styles"
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar"
import LanguagePicker from "component/language-picker/LanguagePicker"
import { useSelector } from "react-redux"
import { selectThemeMode } from "styles/stylesSlice"
import { themeNavAndFooterBg } from "styles/MuiTheme"
import { Link } from "react-router-dom"
import { RoutesList } from "lib/RoutesList"
import Box from "@mui/material/Box"

const drawerWidth = 240

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const NavDrawerHeaderDesktop = ({
    open,
    isAuthenticated,
}: {
    open: boolean
    isAuthenticated: boolean
}) => {
    const themeMode = useSelector(selectThemeMode)
    return (
        <>
            <AppBar
                sx={{
                    boxShadow: "none",
                    display: { xs: "none", sm: "block" },
                    backgroundColor: themeNavAndFooterBg(themeMode),
                    left: 0,
                    position: "absolute",
                    zIndex: 0,
                }}
                open={open}
            >
                <Toolbar>
                    <Box
                        sx={{
                            position: "absolute",
                            left: 80,
                        }}
                    >
                        {!open && (
                            <Link
                                to={
                                    isAuthenticated
                                        ? RoutesList.prv.Invite
                                        : "/"
                                }
                            >
                                <img
                                    width="130"
                                    height="auto"
                                    src="/images/picture/logo-bo-cbll-restaurantes.svg"
                                    alt={"logo"}
                                />
                            </Link>
                        )}
                    </Box>

                    <Box
                        sx={{
                            position: "absolute",
                            right: 20,
                        }}
                    >
                        <LanguagePicker />
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default NavDrawerHeaderDesktop

import { createSlice } from "@reduxjs/toolkit"
import { AppState } from "config/Store"

export interface StyleState {
    mode: "light" | "dark"
}

const INITIAL_STATE: StyleState = {
    mode: "light",
}

export const stylesSlice = createSlice({
    name: "styles",
    initialState: INITIAL_STATE,
    reducers: {
        setThemeMode: (state, action) => {
            state.mode = action.payload
        },
    },
})

export const { setThemeMode } = stylesSlice.actions

export const selectThemeMode = (state: AppState) => state.styles.mode

export default stylesSlice
